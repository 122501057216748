import React from "react"
import TitleCard from "./Cards/TitleCard"
import { useDispatch, useSelector } from "react-redux"
import { GoodSentences } from "../type/Article"
import { t } from "i18next"

interface PropsType {
  height?: string
  useCollapse: boolean
}

const GoodSentencesList: React.FC<PropsType> = ({ height, useCollapse }) => {
  const dispatch = useDispatch()

  const { selectedArticle, selectedArticleLevel } = useSelector(
    (state: any) => state.news
  )

  return (
    <TitleCard
      height={height}
      isHiddenScrollBar={true}
      title={t("FullNewsCard.sentence-structure") as string}
      useCollapse={useCollapse}
      topMargin="mt-2">
      {selectedArticleLevel &&
        Array.isArray(selectedArticle[selectedArticleLevel].goodSentences) &&
        selectedArticle[selectedArticleLevel].goodSentences.map(
          (sentence: GoodSentences, index: number) => {
            return (
              <div key={index} className=" my-4">
                <span className="underline">{sentence.sentence}</span>
                <div className="mt-1 text-gray-400 rounded-lg text-sm">
                  {sentence.reason}
                </div>

                <div className="mt-1 text-gray-400 rounded-lg text-sm">
                  {sentence.reasonCHI}
                </div>
              </div>
            )
          }
        )}
    </TitleCard>
  )
}
export default GoodSentencesList
