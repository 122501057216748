import React, { useEffect, useState } from "react"
import classNames from "classnames"
import { Trans } from "react-i18next"

import PaginationCard from "./PaginationCard"
import WordCard from "../PaginationCardComponents/WordCard"
import EditOrgUser from "../AdminUser/EditOrgUser"
import ArticleCard from "../PaginationCardComponents/ArticleCard"
import MiniUserDashboard from "../AdminUser/MiniUserDashboard"
import QuizCard from "../PaginationCardComponents/QuizCard"

import {
  ArticleReadHistory,
  OrgStudentUser,
  User,
  WordObject,
} from "../../type/User"
import {
  getSaveArticleReadHistoryCondition,
  getUserCollected,
  getUserReadArticles,
} from "../../helperFunction/userDBHelper"

import { Quiz } from "../../type/Quiz"
import {
  getUserAssignedQuiz,
  getUserAssignedQuizCondition,
  getUsersQuizSubmission,
} from "../../helperFunction/quizDBHelper"

interface PropsType {
  user: OrgStudentUser
  isCard?: boolean
}

enum TabType {
  OVERVIEW,
  WORD,
  ARTICLE,
  EXERCISE,
  PROFILE,
}

const PAGINATION_COUNT = 10

const OrgUserReviewCard: React.FC<PropsType> = ({ user, isCard }) => {
  return (
    <div className="h-full flex flex-col">
      {/*Content*/}
      <div className="grow  h-full overflow-hidden">
        <div className="px-2">
          <EditOrgUser data={user} />
        </div>
      </div>
    </div>
  )
}
export default OrgUserReviewCard
