import React from "react"
import { useEffect, useState } from "react"
import classNames from "classnames"

export enum InputSize {
  SM,
  MD,
  LG,
  XL,
}

interface PropsType {
  labelTitle: string
  labelStyle?: string
  type: string
  containerStyle: string
  defaultValue: string
  placeholder?: string
  updateFormValue?: (val: any) => void
  updateType?: string
  onTimeout?: (val: string) => void
  size?: InputSize
  noSpecialChar?: boolean
}

export interface InputUpdateType {
  updateType: string
  //string | string[] | Date
  value: any
}

const InputText: React.FC<PropsType> = ({
  labelTitle,
  labelStyle,
  type,
  containerStyle,
  defaultValue,
  placeholder,
  updateFormValue,
  updateType,
  onTimeout,
  size,
  noSpecialChar,
}) => {
  const [value, setValue] = useState(defaultValue)
  const [typingTimeout, setTypingTimeout] = useState<number | undefined>(
    undefined
  )

  useEffect(() => {
    return () => {
      // Clear the timeout when the component unmounts
      clearTimeout(typingTimeout)
    }
  }, [typingTimeout])

  const updateInputValue = (val: string) => {
    // Perform regex check to allow only spaces and English letters
    setValue(noSpecialChar ? val.replace(/[^a-zA-Z0-9_]/g, "") : val)

    if (updateFormValue) {
      updateFormValue({ updateType, value: val })
    }
    // Clear the previous timeout
    clearTimeout(typingTimeout)
    // Set a new timeout

    if (onTimeout) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      setTypingTimeout(setTimeout(() => onTimeout(val), 1500))
    }
  }

  return (
    <div className={`form-control w-full my-2 ${containerStyle}`}>
      <label className="label">
        <span className={"label-text text-base-content " + labelStyle}>
          {labelTitle}
        </span>
      </label>
      <input
        type={type || "text"}
        value={value}
        placeholder={placeholder || ""}
        onChange={(e) => updateInputValue(e.target.value)}
        className={classNames("input  input-bordered w-full ", {
          "input-sm": size === InputSize.SM,
          "input-md": size === InputSize.MD,
          "input-lg": size === InputSize.LG,
          "text-2xl": size === InputSize.XL,
        })}
      />
    </div>
  )
}

export default InputText
