import { getFirestore } from "firebase/firestore"
import firebaseApp from "../config/firebase"
import { getQuiz } from "./quizDBHelper"
import { Quiz } from "../type/Quiz"

const db = getFirestore(firebaseApp)

/**
 * get all available questions
 */
const getQuestionsFromQuiz = (
  quizID: string
): Promise<
  | {
      success: true
      data: Quiz
    }
  | {
      success: false
    }
> => {
  return new Promise(async (resolve) => {
    const getState = await getQuiz(quizID)
    if (getState.success) {
      return resolve({
        success: true,
        data: getState.data,
      })
    }
    return resolve({
      success: false,
    })
  })
}

export { getQuestionsFromQuiz }
