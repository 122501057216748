import { createSlice } from "@reduxjs/toolkit"

interface ttsManagerState {
  currentText: string
  isSpeaking: boolean
}

export interface SpeechProps {
  text: string
  pitch: number
  rate: number
  volume: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10
  lang: string
  voice: string
}

export const ttsSlice = createSlice({
  name: "tts",
  initialState: {
    currentText: "",
    isSpeaking: false,
    text: "",
    pitch: 5,
    rate: 4,
    volume: 10,
    lang: "en-GB",
    voice: "Google US English",
  } as ttsManagerState & SpeechProps,
  reducers: {
    setCurrentText: (state, action) => {
      state.currentText = action.payload
    },
    setTTSText: (state, action) => {
      state.text = action.payload
    },
    setIsSpeaking: (state, action) => {
      state.isSpeaking = action.payload
    },
  },
})

export const { setCurrentText, setTTSText, setIsSpeaking } = ttsSlice.actions

export default ttsSlice.reducer
