import { DateObj } from "./SystemType"
import { Question } from "./Question"
import { ARTICLE_LEVEL } from "./Article"

export enum QuizType {
  NORMAL = "0",
  READING_COMPREHENSION = "1",
  PERSONAL_VOCAB = "2",
}

interface QuizCommon {
  id?: string
  title: string
  description: string
  createUserID: string
  createDate: Date | DateObj
  isPublish: boolean
  isPublic: boolean
  questions: Question[]
  orgID: string
  orgGroups: string[]
  userID: string[]
  timeLimit: number
  startDate: Date | DateObj
  endDate: Date | DateObj
  totalPoint: number
}

interface NormalQuiz {
  type: QuizType.NORMAL
}

interface PersonalVocab {
  type: QuizType.PERSONAL_VOCAB
}

interface ReadingQuiz {
  type: QuizType.READING_COMPREHENSION
  articleID: string
  articleLevel: ARTICLE_LEVEL | ""
}

export type Quiz = QuizCommon & (NormalQuiz | ReadingQuiz | PersonalVocab)

export const defaultQuiz: Quiz = {
  id: "",
  title: "",
  description: "",
  createUserID: "",
  createDate: new Date(),
  isPublish: false,
  isPublic: false,
  questions: [],
  orgID: "",
  orgGroups: [],
  userID: [],
  timeLimit: 0,
  startDate: new Date(),
  endDate: new Date(),
  type: QuizType.NORMAL,
  totalPoint: 0,
}

export const exampleQuiz: Quiz = {
  id: "aa",
  title: "quiz 1",
  description: "test",
  createUserID: "abc",
  createDate: new Date(),
  isPublish: true,
  isPublic: true,
  questions: [],
  orgID: "",
  orgGroups: [],
  userID: [],
  timeLimit: 0,
  startDate: new Date(),
  endDate: new Date(),
  type: QuizType.NORMAL,
  totalPoint: 0,
}
