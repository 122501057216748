import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { objectDateToString } from "../utils/utiltyHelper"
import { getQuestionsFromQuiz } from "../helperFunction/questionDBHelper"
import {
  getOrgUsersByOrgID,
  getOrgUsersByOrgIDCondition,
} from "../helperFunction/orgDBHelper"
import { User } from "../type/User"
import { UserWithSubmission } from "../type/Submission"
import { ListQuizViewItem } from "../pages/protected/ListQuiz"
import {
  getAllUserQuizWithSubmission,
  getUserAssignedQuizCondition,
} from "../helperFunction/quizDBHelper"

export interface UserManagerState {
  isLoading: boolean
  hasMore: boolean
  orgUsers: User[]
  selectedUser: User | object
  isSelectedUser: boolean
  condition: getOrgUsersByOrgIDCondition

  userQuizCondition: getUserAssignedQuizCondition
  isLoadedUserQuiz: boolean
  hasMoreUserQuiz: boolean
  selectedUserQuizzes: UserWithSubmission[]
}

export const getOrgUsers = createAsyncThunk(
  "userManagement/getOrgUsers",
  async (condition: getOrgUsersByOrgIDCondition) => {
    return objectDateToString(await getOrgUsersByOrgID(condition))
  }
)

export const getOrgGroup = createAsyncThunk(
  "userManagement/getGroup",
  async (quizID: string) => {
    return objectDateToString(await getQuestionsFromQuiz(quizID))
  }
)

export const getUserQuizWithSubmission = createAsyncThunk(
  "userManagement/getUserQuizWithSubmission",
  async ({
    uid,
    orgID,
    orgGroups,
    condition,
  }: {
    uid: string
    orgID: string
    orgGroups: string[]
    condition: getUserAssignedQuizCondition
  }) => {
    return objectDateToString(
      await getAllUserQuizWithSubmission(uid, orgID, orgGroups, condition)
    )
  }
)

export const updateLocalUser = (
  orgUsers: User[],
  updatedUser: User
): User[] => {
  const userIndex = orgUsers.findIndex((user) => user.id === updatedUser.id)

  if (userIndex !== -1) {
    // Create a copy of the orgUsers array
    const updatedOrgUsers = [...orgUsers]

    // Update the user at the found index with the updatedUser
    updatedOrgUsers[userIndex] = updatedUser

    return updatedOrgUsers
  }

  // If the user was not found, return the original array
  return orgUsers
}

export const userManagementSlice = createSlice({
  name: "userManagement",
  initialState: {
    isLoading: false,
    hasMore: false,
    orgUsers: [],
    selectedUser: {},
    isSelectedUser: false,
    condition: {
      orgID: "",
      orgGroups: "",
      startAfter: "",
      endAt: "",
    },
    userQuizCondition: {
      startAfter: "",
      endAt: "",
      type: ListQuizViewItem.USER,
      uid: "",
    },
    isLoadedUserQuiz: false,
    hasMoreUserQuiz: false,
    selectedUserQuizzes: [],
  } as UserManagerState,
  reducers: {
    setSelectedUser: (state, action) => {
      state.selectedUser = action.payload
      state.isSelectedUser = true
    },
    setSearchOrgUserCondition: (state, action) => {
      state.condition = action.payload
    },
    setOrgUserQuizCondition: (state, action) => {
      state.userQuizCondition = action.payload
    },
    updateUserLocally: (state, action) => {
      state.orgUsers = updateLocalUser(state.orgUsers, action.payload)
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getOrgUsers.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getOrgUsers.fulfilled, (state, action) => {
        if (action.payload.success) {
          state.orgUsers = action.payload.data
          state.hasMore = action.payload.hasMore
        }
        state.isLoading = false
      })
      .addCase(getOrgUsers.rejected, (state) => {
        state.isLoading = false
      })
      //getUserQuizWithSubmission
      .addCase(getUserQuizWithSubmission.pending, (state) => {
        state.isLoadedUserQuiz = false
      })
      .addCase(getUserQuizWithSubmission.fulfilled, (state, action) => {
        if (action.payload.success) {
          state.selectedUserQuizzes = action.payload.data
          state.hasMoreUserQuiz = action.payload.hasMore
        }
        state.isLoadedUserQuiz = true
      })
      .addCase(getUserQuizWithSubmission.rejected, (state) => {
        state.isLoadedUserQuiz = false
      })
  },
})

export const {
  setSelectedUser,
  setSearchOrgUserCondition,
  setOrgUserQuizCondition,
  updateUserLocally,
} = userManagementSlice.actions

export default userManagementSlice.reducer
