import React, { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { defaultQuiz, Quiz, QuizType } from "../../type/Quiz"
import { t } from "i18next"
import InputText, { InputSize, InputUpdateType } from "../Input/InputText"
import { v4 as uuidv4 } from "uuid"
import { addQuiz, removeQuiz } from "../../helperFunction/quizDBHelper"
import { addNewQuiz, removeQuizRedux } from "../../redux/quizManagementSlice"
import { showNotification } from "../../redux/headerSlice"
import { Trans } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { Submission } from "../../type/Submission"
import { isEmpty } from "../../utils/utiltyHelper"
import { Question } from "../../type/Question"
import UserQuestionCardManager from "../UserQuiz/UserQuestionCardManager"
import { setUserAnswer } from "../../redux/quizSlice"

interface PropsType {
  closeModal: () => void
  extraObject: {
    quiz: Quiz
    submission: Submission
  }
}

const ViewQuizResultModalBody: React.FC<PropsType> = ({
  closeModal,
  extraObject,
}) => {
  const dispatch = useDispatch()
  let history = useNavigate()

  useEffect(() => {
    if (extraObject.submission) {
      dispatch(setUserAnswer(extraObject.submission))
    }
  }, [])

  const hasPreview =
    !isEmpty(extraObject.quiz) &&
    extraObject.submission &&
    extraObject.quiz.questions
  return (
    <>
      <div className="w-full h-full overflow-y-scroll">
        {hasPreview ? (
          extraObject.quiz.questions.map((q: Question, index: number) => {
            return <UserQuestionCardManager key={q.id} data={q} index={index} />
          })
        ) : (
          <div className="text-center text-2xl">
            <Trans>UserOverviewQuiz.not-yet-submission</Trans>
          </div>
        )}
      </div>

      <div className="modal-action">
        <button className="btn btn-ghost" onClick={() => closeModal()}>
          <Trans>RemoveQuizModal.cancel</Trans>
        </button>
      </div>
    </>
  )
}

export default ViewQuizResultModalBody
