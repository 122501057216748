import { DateObj } from "./SystemType"
import { OrgStudentUser, User } from "./User"
import { Quiz } from "./Quiz"

export interface UserWithSubmission {
  user: OrgStudentUser
  submission: Submission
}

export interface QuizWithSubmission {
  quiz: Quiz
  submission: Submission | null
}

export type Answer = {
  questionID: string
  score: number
  answer: string[] | string
  comment: string
}

export interface Submission {
  id?: string
  createUserID: string
  createDate: Date | DateObj
  lastUpdate: Date | DateObj
  submitDate: Date | DateObj
  points: number
  isScored: boolean
  isSubmitted: boolean
  answers: Answer[]
}

export const defaultAnswerCommon: Submission = {
  createUserID: "",
  createDate: new Date(),
  lastUpdate: new Date(),
  submitDate: new Date(),
  points: 0,
  isScored: false,
  isSubmitted: false,
  answers: [],
}

export const defaultAnswer: Answer = {
  questionID: "",
  score: 0,
  answer: "",
  comment: "",
}

export const defaultFBAnswer: Answer = {
  questionID: "",
  score: 0,
  answer: [],
  comment: "",
}
