import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { objectDateToString } from "../utils/utiltyHelper"

import { getOrgProfile } from "../helperFunction/orgDBHelper"
import { Organization, OrganizationGroup } from "../type/Organization"

export interface orgManagerState {
  organizationProfile: Organization | object
  isLoading: boolean
}

const createNewOrgGroup = (org: Organization, newGroup: OrganizationGroup) => {
  return {
    ...org,
    orgGroups: org.orgGroups.concat(newGroup),
  }
}

export const getOrgProfileByID = createAsyncThunk(
  "orgManagement/getProfile",
  async (orgID: string) => {
    return objectDateToString(await getOrgProfile(orgID))
  }
)

export const getTodayOnlineCount = createAsyncThunk(
  "orgManagement/userOnlineCount",
  async (orgID: string) => {
    return objectDateToString(await getOrgProfile(orgID))
  }
)

export const orgManagementSlice = createSlice({
  name: "userManagement",
  initialState: {
    organizationProfile: {},
    isLoading: true,
  } as orgManagerState,
  reducers: {
    createNewGroup: (state, action) => {
      state.organizationProfile = createNewOrgGroup(
        state.organizationProfile as Organization,
        action.payload
      )
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getOrgProfileByID.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getOrgProfileByID.fulfilled, (state, action) => {
        if (action.payload.success) {
          state.organizationProfile = action.payload.data
        }
        state.isLoading = false
      })
      .addCase(getOrgProfileByID.rejected, (state) => {
        state.isLoading = false
      })
  },
})

export const { createNewGroup } = orgManagementSlice.actions

export default orgManagementSlice.reducer
