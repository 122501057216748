import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { Trans } from "react-i18next"
import WordCard from "../PaginationCardComponents/WordCard"
import PaginationCard, { PaginationCardSize } from "../Cards/PaginationCard"

import { WordObject } from "../../type/User"
import {
  filteredWordObjects,
  getWordsByFolderName,
} from "../../helperFunction/bookmarkHelper"
import {
  chatGPTMC,
  ChatGPTQuestion,
  Question,
  QuestionType,
} from "../../type/Question"
import {
  addNewQuestion,
  addNewQuiz,
  setIsGenerating,
  setPrompt,
} from "../../redux/quizManagementSlice"
import {
  GENERATIVE_TYPE,
  getGeneratedQuestion,
} from "../../helperFunction/assistantDBHelper"
import { convertGeneratedMCQuestion } from "../../helperFunction/generateQuestionHelper"
import { v4 as uuidv4 } from "uuid"
import { pickRandomItems } from "../../helperFunction/questionHelper"
import { defaultQuiz, Quiz, QuizType } from "../../type/Quiz"
import { addQuiz } from "../../helperFunction/quizDBHelper"
import { showNotification } from "../../redux/headerSlice"
import CustomAlert from "../Alert"
import { ALERT_TYPE, BUTTON_TYPE } from "../../enum/ALERT_SYSYEM"
import { faCircleXmark, faClock } from "@fortawesome/free-solid-svg-icons"

interface PropsType {
  closeModal: () => void
  extraObject: {
    folderName: string
  }
}

const PAGINATION_COUNT = 10

const StudentVocabQuizCreationModalBody: React.FC<PropsType> = ({
  closeModal,
  extraObject,
}) => {
  const dispatch = useDispatch()
  let history = useNavigate()

  const { isGenerating } = useSelector((state: any) => state.quizMarker)

  const auth = useSelector((state: any) => {
    return state.firebase.auth
  })

  const { isLoadingCollected, collected } = useSelector(
    (state: any) => state.user
  )

  const [words, setWords] = useState<WordObject[]>([])
  const [wordIndex, setWordIndex] = useState<number>(0)
  const [inLimit, setInLimit] = useState<boolean>(false)

  useEffect(() => {
    dispatch(setIsGenerating(false))
  }, [])

  useEffect(() => {
    if (extraObject.folderName && !isLoadingCollected) {
      const words: WordObject[] = getWordsByFolderName(
        collected,
        extraObject.folderName
      )
      setWords(words)
      setInLimit(filteredWordObjects(words).length >= 10)
    }
  }, [extraObject, collected, isLoadingCollected])

  const sendToChatgpt = () => {
    dispatch(setIsGenerating(true))

    const wordObjects: WordObject[] = pickRandomItems(
      filteredWordObjects(words),
      20
    ) as WordObject[]
    const wordsInString: string = Array.isArray(words)
      ? wordObjects.map((e) => e.name).join(" ,")
      : ""

    getGeneratedQuestion(
      GENERATIVE_TYPE.GENERATE_WORD_FORM_EXERCISE,
      wordsInString
    ).then((result) => {
      if (result.success) {
        const allQuestion: Question[] = result.data.questions.map(
          (q: ChatGPTQuestion) => ({
            ...convertGeneratedMCQuestion(q as chatGPTMC),
            id: uuidv4(),
          })
        )

        const totalPoints = allQuestion.reduce(
          (acc: number, question: Question) => acc + question.points,
          0
        )

        const date = new Date()
        const quizID = uuidv4()
        const newQ: Quiz = {
          ...defaultQuiz,
          title: `Vocab Quiz ${date.getFullYear()}-${
            date.getMonth() + 1
          }-${date.getDate()}`,
          id: quizID,
          questions: allQuestion,
          isPublish: true,
          orgID: auth.orgID,
          createUserID: auth.uid,
          type: QuizType.PERSONAL_VOCAB,
          totalPoint: totalPoints,
        }

        addQuiz(newQ).then((result) => {
          if (result.success) {
            dispatch(setIsGenerating(false))
            dispatch(
              showNotification({
                message: "New Quiz Added!",
                status: 1,
              })
            )
            history(`/app/quiz/${quizID}`)
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            dispatch(closeModal())
          }
        })
      }
      dispatch(setIsGenerating(false))
    })
  }

  return (
    <>
      <div className="grow h-full overflow-y-scroll">
        <CustomAlert
          title={"StudentVocabQuizCreationModalBody.use-the-following-words"}
          alertType={ALERT_TYPE.INFO}
          buttonType={{
            type: BUTTON_TYPE.NONE,
          }}
          icon={faClock}
        />

        {!inLimit && (
          <CustomAlert
            title={"StudentVocabQuizCreationModalBody.not-in-limited"}
            alertType={ALERT_TYPE.ERROR}
            buttonType={{
              type: BUTTON_TYPE.NONE,
            }}
            icon={faCircleXmark}
          />
        )}
        <PaginationCard
          data={words.slice(
            wordIndex * PAGINATION_COUNT,
            (wordIndex + 1) * PAGINATION_COUNT
          )}
          itemsPerPage={PAGINATION_COUNT}
          currentPage={wordIndex}
          onPageChange={(index: number) => setWordIndex(index)}
          size={PaginationCardSize.XL}
          component={WordCard}
        />
      </div>
      <div className="modal-action">
        {isGenerating && (
          <div className="w-full my-auto rounded-lg">
            <span className="text-gray-500">
              <Trans>StudentVocabQuizCreationModalBody.ai-is-writing</Trans>
            </span>
            <span className="text-gray-500 ml-2 my-auto loading loading-dots loading-sm"></span>
          </div>
        )}

        <button
          className="btn btn-ghost"
          onClick={() => {
            dispatch(setIsGenerating(false))
            closeModal()
          }}>
          <Trans>StudentVocabQuizCreationModalBody.cancel</Trans>
        </button>
        <button
          className="btn text-white btn-primary px-6"
          disabled={!inLimit || isGenerating}
          onClick={() => sendToChatgpt()}>
          <Trans>StudentVocabQuizCreationModalBody.create</Trans>
        </button>
      </div>
    </>
  )
}

export default StudentVocabQuizCreationModalBody
