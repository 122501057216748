import React, {
  Dispatch,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from "react"
import SpeakerWaveIcon from "@heroicons/react/24/outline/SpeakerWaveIcon"
import TitleCard from "../Cards/TitleCard"
import { setSelectedText } from "../../redux/toolsSlice"
import { useDispatch, useSelector } from "react-redux"
import { DictionaryType, Meaning, Phonetic } from "../../type/Dictionary"
import DivContentLoader from "../DivContentLoader"
import classNames from "classnames"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faStar } from "@fortawesome/free-solid-svg-icons"
import { openModal } from "../../redux/modalSlice"
import { MODAL_BODY_TYPES } from "../../utils/globalConstantUtil"
import Result from "./Result"
import { ModalSize } from "../../containers/ModalLayout"
import { WordObject } from "../../type/User"
import { updateWordToCollected } from "../../redux/userSlice"
import { SwipeType } from "../BookMark/Card"
import { getTranslation } from "../../helperFunction/dictionaryHelper"
import { SpeechProps } from "../TTSComponent"

const synth = window.speechSynthesis

interface DictionaryPropsType {
  height?: string
  setMenuOpen?: Dispatch<any>
  selectable?: boolean
}

const Dictionary: React.FC<DictionaryPropsType> = (
  { height, setMenuOpen, selectable },
  ref: any
) => {
  const dispatch = useDispatch()

  const { selectedText } = useSelector((state: any) => state.tools)
  const { collected, selectedCollected } = useSelector(
    (state: any) => state.user
  )

  const voices = useMemo(() => synth.getVoices(), [])
  const [isSpeaking, setIsSpeaking] = useState("")
  const [meanings, setMeanings] = useState<Meaning[]>([])
  const [phonetics, setPhonetics] = useState<Phonetic[]>([])
  const [word, setWord] = useState<string>("")
  const [wordCHI, setWordCHI] = useState<string>("")
  const [error, setError] = useState<string>("")
  const [isLoading, setIsLoaded] = useState<boolean>(false)

  const [speechSetting, setSpeechSetting] = useState<SpeechProps>({
    text: "",
    pitch: 5,
    rate: 4,
    volume: 10,
    lang: "en-GB",
    voice: "Google US English",
  })

  useEffect(() => {
    if (!selectedText.trim()) return reset()
    const debounce = setTimeout(() => {
      setIsLoaded(true)
      dictionaryApi(selectedText)
    }, 100)
    return () => clearTimeout(debounce)
  }, [selectedText])

  const openAddWordModal = () => {
    dispatch(
      openModal({
        title: "Add new word",
        bodyType: MODAL_BODY_TYPES.ADD_WORD_MODAL,
        size: ModalSize.LG,
        extraObject: {
          selectedText: selectedText,
        },
      })
    )
  }

  const dictionaryApi = (text: string) => {
    getTranslation(text).then((result) => {
      if (result.success) {
        setMeanings(result.data.meanings)
        setPhonetics(result.data.phonetics)
        setWord(result.data.word)
        setWordCHI(result.data.wordCHI)
        setError("")
        setIsLoaded(false)
      }
    })
  }

  const reset = () => {
    setIsSpeaking("")
    setError("")
    setMeanings([])
    setPhonetics([])
    setWord("")
    setWordCHI("")
  }

  useImperativeHandle(ref, () => ({
    returnData() {
      return {
        wordCHI: wordCHI,
        partOfSpeech: meanings.map((e) => e.partOfSpeech),
      }
    },
  }))

  const startSpeech = (text: string) => {
    const utterance = new SpeechSynthesisUtterance(text)
    // const voice = voices.find((voice) => voice.name === voiceSelected)
    // if (voice) {
    //   utterance.voice = voice
    // }
    utterance.pitch = speechSetting.pitch / 5
    utterance.rate = speechSetting.rate / 5
    utterance.volume = speechSetting.volume / 10
    utterance.lang = speechSetting.lang
    synth.speak(utterance)
  }
  const handleSpeech = (e: any) => {
    e.preventDefault()
    if (!selectedText.trim()) return reset()
    if (!synth.speaking) {
      startSpeech(selectedText)
      setIsSpeaking("speak")
    } else {
      synth.cancel()
    }

    setInterval(() => {
      if (!synth.speaking) {
        setIsSpeaking("")
      }
    }, 1000)
  }

  if (selectedText.trim() === "" || error) {
    return <></>
  } else if (isLoading) {
    return (
      <TitleCard
        height={height}
        title={selectedText}
        topMargin="mt-2"
        TopSideButtons={
          <button className="btn btn-sm btn-ghost btn-circle" disabled={true}>
            <FontAwesomeIcon
              icon={faStar}
              className="text-gray-500 font-bold my-auto"
            />
          </button>
        }>
        <form>
          <div className="row">
            <div className="flex w-full">
              <button className="btn btn-sm" disabled={true}>
                <SpeakerWaveIcon className="w-8 h-8" />
              </button>
            </div>
          </div>
        </form>

        <ul>
          <li className="my-1">
            <DivContentLoader w={80} h={20} r={2} />
          </li>
          <li className="my-1">
            <DivContentLoader w={100} h={20} r={2} />
          </li>

          <li className="contain">
            <h3 className="font-bold">
              <DivContentLoader w={100} h={25} r={2} />
            </h3>
            <section className=" ">
              <DivContentLoader w={"100%"} h={250} r={2} />
            </section>
          </li>
        </ul>
      </TitleCard>
    )
  }
  const isWordExists = collected.some(
    (e: WordObject) => e.name === selectedText
  )
  return (
    <TitleCard
      height={height}
      title={selectedText}
      topMargin="mt-2"
      TopSideButtons={
        <button
          className="btn btn-sm btn-ghost btn-circle"
          onClick={() => {
            if (isWordExists) {
              const wordObj: WordObject = collected.find(
                (e: WordObject) => e.name === selectedText
              )
              if (wordObj) {
                dispatch(
                  updateWordToCollected({
                    word: wordObj,
                    action: SwipeType.DELETE,
                  })
                )
              }
            } else {
              if (setMenuOpen) {
                setMenuOpen(false)
              }
              openAddWordModal()
            }
          }}>
          <FontAwesomeIcon
            icon={faStar}
            className={classNames("font-bold my-auto", {
              "text-gray-500": !isWordExists,
              "text-primary": isWordExists,
            })}
          />
        </button>
      }>
      <form>
        <div className="row">
          <div className="flex w-full">
            <button className="btn btn-sm" onClick={handleSpeech}>
              <SpeakerWaveIcon className="w-8 h-8" />
              {isSpeaking}
            </button>
          </div>
        </div>
      </form>

      <Result
        word={word}
        wordCHI={wordCHI}
        meanings={meanings}
        phonetics={phonetics}
        setText={setSelectedText}
      />
    </TitleCard>
  )
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export default React.forwardRef(Dictionary)
