import React, { useEffect, useImperativeHandle, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { FBQuestion } from "../../type/Question"
import classNames from "classnames"
import { Answer, defaultFBAnswer, Submission } from "../../type/Submission"
import { ALERT_TYPE, BUTTON_TYPE } from "../../enum/ALERT_SYSYEM"
import { faCircleXmark, faTimes } from "@fortawesome/free-solid-svg-icons"
import CustomAlert from "../../components/Alert"
import { SubmissionUpdate, updateUserAnswer } from "../../redux/quizSlice"
import { isEmpty } from "../../utils/utiltyHelper"

interface PropsType {
  data: FBQuestion
}

const FBQuestionCard: React.FC<PropsType> = ({ data }, ref: any) => {
  const dispatch = useDispatch()

  const userSubmission: Submission = useSelector((state: any) => {
    return state.quiz.userSubmission
  })

  const userAnsweredResult: Answer = useSelector((state: any) => {
    if (!isEmpty(state.quiz.userSubmission) && state.quiz.userSubmission) {
      console.log("in")
      const ans: Answer = state.quiz.userSubmission.answers.find(
        (e: Answer) => e.questionID === data.id
      )
      if (ans) {
        return ans
      }

      let arr = Array(data.answer.length).fill("")
      return {
        ...defaultFBAnswer,
        answer: arr,
      }
    }

    let arr = Array(data.answer.length).fill("")
    return {
      ...defaultFBAnswer,
      answer: arr,
    }
  })

  const isAnswered = userSubmission.isScored

  const onTypeAnswer = (index: number, val: string) => {
    let newAns: string[] = [...(userAnsweredResult.answer as string[])]
    newAns[index] = val

    const updatedData: SubmissionUpdate = {
      questionID: data.id as string,
      answer: newAns,
    }

    dispatch(updateUserAnswer(updatedData))
  }

  useImperativeHandle(ref, () => ({
    returnData() {
      return userAnsweredResult?.answer
    },
  }))

  if (isAnswered) {
    return (
      <div>
        <p className="text-lg">{data.question}</p>
        {/*  Options*/}
        <div className="grid gap-2 my-2">
          {data.answer.map((ansString, index) => {
            const userAns = userAnsweredResult.answer[index]
            const realAns = ansString
            const isCorrectAns = userAns === realAns
            const isWrong = userAns !== realAns
            const notSelected = !userAns && !isCorrectAns
            return (
              <div
                className={classNames(
                  "cursor-pointer p-4 w-full text-lg rounded",
                  {
                    "bg-green-200": isCorrectAns,
                    "bg-red-200 line-through": isWrong,
                  }
                )}
                key={ansString}>
                {userAns}
                {!isCorrectAns && (
                  <div className="mx-4  inline-block text-red-700 font-bold">
                    {ansString}
                  </div>
                )}
              </div>
            )
          })}
        </div>

        {userSubmission.isScored ? (
          <div>
            <div className="text-2xl bg-green-100 text-green-700 rounded-2xl text-center w-24 p-4">
              {userAnsweredResult.score} / {data.points}
            </div>
          </div>
        ) : (
          <CustomAlert
            title={"QuestionCardManager.system-processing"}
            alertType={ALERT_TYPE.ERROR}
            buttonType={{
              type: BUTTON_TYPE.NONE,
            }}
            icon={faCircleXmark}
          />
        )}
      </div>
    )
  }
  return (
    <div>
      <p className="text-lg">{data.question}</p>

      {/*  Options*/}
      <div className="grid gap-2 my-2">
        {Array.isArray(userAnsweredResult.answer) &&
          userAnsweredResult.answer.map((ansItem, index) => {
            return (
              <div className="flex gap-2" key={index}>
                <div className="p-4 text-gray-400 rounded">{index + 1}</div>
                <input
                  type="text"
                  placeholder="Answer"
                  className="input input-bordered w-full "
                  value={ansItem}
                  onChange={(e) => onTypeAnswer(index, e.target.value)}
                />
              </div>
            )
          })}
      </div>
    </div>
  )
}
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export default React.forwardRef(FBQuestionCard)
