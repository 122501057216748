export enum ROLE {
  USER = "user",
  ORG_USER = "org-user",
  ORG = "org",
  ORG_ADMIN = "org-admin",
  ADMIN = "administrator",
  DEFAULT = "",
}

export enum ASSET_TYPE {
  TEXT = "text",
  CHATGPT_TEXT_QUESTION = "chatgpt-question",
  CHATGPT_RECOMMENDATION = "chatgpt-recommendation",
  CHATGPT_TEXT_REPLY = "chatgpt-reply",
  CHATGPT_TEXT = "chatgpt",
  ASSISTANT_QUESTION = "assistant-question",
  CLEAR_UP = "clear-up",
  ASSISTANT_RESPONSE = "assistant-response",
}
