import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import InputText, {
  InputSize,
  InputUpdateType,
} from "../../components/Input/InputText"
import { showNotification } from "../../redux/headerSlice"
import { Quiz } from "../../type/Quiz"
import { SelectSize } from "../Input/SelectBox"
import { OrganizationGroup } from "../../type/Organization"
import { useNavigate } from "react-router-dom"
import MultiSelectBox from "../Input/MultiSelectBox"
import CustomDatePicker from "../Input/DatePicker"
import { updateQuiz } from "../../helperFunction/quizDBHelper"

import { faUpRightFromSquare } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import { timeConverter } from "../../utils/utiltyHelper"
import { ALERT_TYPE, BUTTON_TYPE } from "../../enum/ALERT_SYSYEM"
import Alert, { CustomAlertType, defaultCustomAlertType } from "../Alert"
import { orgGroupIDToSelectOptions } from "../../helperFunction/organizationHelper"
import { setEditQuestionID } from "../../redux/quizManagementSlice"

interface PropsType {
  closeModal: () => void
  extraObject: {
    quiz: Quiz
  }
}

const IssueNewQuizModalBody: React.FC<PropsType> = ({
  closeModal,
  extraObject,
}) => {
  const dispatch = useDispatch()
  let history = useNavigate()

  const auth = useSelector((state: any) => {
    return state.firebase.auth
  })

  const { isLoading: isOrgLoading, organizationProfile: p } = useSelector(
    (state: any) => state.org
  )

  const { isLoading: isQuizLoading, quizzes } = useSelector(
    (state: any) => state.quizMarker
  )

  const [newQuiz, setNewQuiz] = useState<Quiz>(extraObject.quiz)
  const [error, setError] = useState<CustomAlertType>({
    type: ALERT_TYPE.ERROR,
    visible: false,
    msg: "",
  })

  const saveNewQuiz = () => {
    if (validateQuiz(newQuiz)) {
      const totalPoints = newQuiz.questions.reduce(
        (acc, question) => acc + question.points,
        0
      )

      updateQuiz({
        ...newQuiz,
        isPublish: true,
        totalPoint: totalPoints,
      }).then((result) => {
        if (result.success) {
          dispatch(setEditQuestionID(""))
          dispatch(
            showNotification({
              message: "The quiz is now be published",
              status: 1,
            })
          )
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          dispatch(closeModal())
        }
      })
    }
  }

  const validateQuiz = (quiz: Quiz): boolean => {
    let isValid = true
    let errorMsg = ""

    if (quiz.questions.length === 0) {
      isValid = false
      errorMsg = "Quiz must have at least one question."
    } else if (quiz.orgGroups.length === 0) {
      isValid = false
      errorMsg = "Quiz must be associated with at least one organization group."
    } else if (quiz.startDate <= new Date()) {
      isValid = false
      errorMsg = "Start date must be after today."
    } else if (quiz.endDate <= quiz.startDate) {
      isValid = false
      errorMsg = "End date must be after the start date."
    } else if (quiz.isPublish) {
      isValid = false
      errorMsg = "isPublish must be false."
    }

    if (!isValid) {
      setError({
        type: ALERT_TYPE.ERROR,
        visible: true,
        msg: errorMsg,
      })
    }

    return isValid
  }

  const updateFormValue = ({ updateType, value }: InputUpdateType) => {
    setError(defaultCustomAlertType)

    if (updateType === "timeLimit") {
      const time = parseInt(value) * 1000 * 60
      setNewQuiz({
        ...newQuiz,
        timeLimit: time,
      })
    } else {
      setNewQuiz({
        ...newQuiz,
        [updateType]: value,
      })
    }
  }

  const goToCreateNewGroup = () => {
    history("/management/user")
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(closeModal())
  }

  if (!isOrgLoading && p && newQuiz) {
    return (
      <>
        <div className="">
          <MultiSelectBox
            labelTitle={"Assigned Group"}
            options={p.orgGroups.map((e: OrganizationGroup) => ({
              label: e.groupName,
              value: e.id,
            }))}
            defaultValue={orgGroupIDToSelectOptions(
              p.orgGroups,
              newQuiz.orgGroups
            )}
            updateType={"orgGroups"}
            placeholder={"Select Group"}
            updateFormValue={updateFormValue}
            size={SelectSize.LG}
            containerStyle="mt-2"
          />

          <div className="inline-block">
            <span className="text-xs mr-2">Cannot find your group?</span>
            <button
              className="btn btn-xs btn-ghost mt-2"
              onClick={() => goToCreateNewGroup()}>
              <FontAwesomeIcon
                icon={faUpRightFromSquare}
                className="text-gray-700 font-bold my-auto"
              />
              Create new group
            </button>
          </div>

          <CustomDatePicker
            labelTitle={"Start Date"}
            updateType={"startDate"}
            selectedDate={timeConverter(newQuiz.startDate) as Date}
            updateFormValue={updateFormValue}
            minDate={new Date()}
          />

          <CustomDatePicker
            labelTitle={"End Date"}
            updateType={"endDate"}
            selectedDate={timeConverter(newQuiz.endDate) as Date}
            updateFormValue={updateFormValue}
            minDate={timeConverter(newQuiz.startDate)}
          />

          <InputText
            type="number"
            defaultValue={(newQuiz.timeLimit / 1000 / 60).toString()}
            updateType="timeLimit"
            containerStyle="mt-4"
            labelTitle="Time Limit (in minutes)"
            updateFormValue={updateFormValue}
            size={InputSize.MD}
          />

          {error.visible && (
            <Alert
              title={error.msg}
              alertType={error.type}
              buttonType={{
                type: BUTTON_TYPE.NONE,
              }}
            />
          )}
        </div>

        <div className="modal-action inline-block float-right text-right">
          <div className="mb-1 w-full block text-right text-gray-400 text-xs">
            you will no longer allow to edit the quiz after this quiz is
            published
          </div>
          <div className="w-full block float-right">
            <button className="btn btn-ghost" onClick={() => closeModal()}>
              Cancel
            </button>
            <button
              className="btn text-white btn-primary px-6"
              onClick={() => saveNewQuiz()}>
              Publish
            </button>
          </div>
        </div>
      </>
    )
  }
  return <div></div>
}

export default IssueNewQuizModalBody
