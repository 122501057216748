import React, { useRef } from "react"
import { Question, QuestionType } from "../../type/Question"

import classNames from "classnames"
import MCQuestionCard from "./MCQuestionCard"
import ShortQuestionCard from "./ShortQuestionCard"
import FBQuestionCard from "./FBQuestionCard"

interface PropsType {
  data: Question
  index: number
}

const UserQuestionCardManager: React.FC<PropsType> = ({ data, index }) => {
  const questionRef = useRef<HTMLDivElement | null>(null)

  return (
    <div
      className={classNames(
        "mt-2 mb-4 card card-compact rounded-lg w-full bg-gray-50 overflow-hidden border border-gray-200"
      )}>
      <div className={classNames(" font-bold p-4  border-b", {})}>
        {index + 1}

        <span className="ml-2 badge badge-primary text-white badge-lg">
          {data.type === QuestionType.MC && "MC"}
          {data.type === QuestionType.SQ && "Short Question"}
          {data.type === QuestionType.FB && "Fill in the blanks"}
        </span>
      </div>
      <div className={classNames("p-4 bg-white")}>
        {data.type === QuestionType.MC && (
          <MCQuestionCard ref={questionRef} data={data} />
        )}
        {data.type === QuestionType.SQ && (
          <ShortQuestionCard ref={questionRef} data={data} />
        )}

        {data.type === QuestionType.FB && (
          <FBQuestionCard ref={questionRef} data={data} />
        )}
      </div>
    </div>
  )

  return <div></div>
}
export default UserQuestionCardManager
