import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { Quiz } from "../type/Quiz"
import { isEmpty, objectDateToString } from "../utils/utiltyHelper"
import {
  getAllUserCreatedQuizzesCondition,
  getUserCreatedQuizzes,
} from "../helperFunction/userDBHelper"
import { Question, exampleMCQuestion } from "../type/Question"
import { UserWithSubmission } from "../type/Submission"
import { SelectOption } from "../components/Input/SelectBox"

export interface QuizManagerState {
  quizzes: Quiz[]
  hasMore: boolean
  isLoading: boolean
  selectedQuiz: Quiz | object
  quizArticle: string
  editQuestionID: string
  userSelection: SelectOption[]
  userWithSubmissions: UserWithSubmission[]
  prompt: string
  isGenerating: boolean
}

export const getCreatedQuizzesThunk = createAsyncThunk(
  "quizMarker/getCreatedQuizzes",
  async ({
    orgID,
    uid,
    condition,
  }: {
    orgID: string
    uid: string
    condition: getAllUserCreatedQuizzesCondition
  }) => {
    return objectDateToString(
      await getUserCreatedQuizzes(orgID, uid, condition)
    )
  }
)

export const quizManagementSlice = createSlice({
  name: "quizManagement",
  initialState: {
    quizzes: [],
    hasMore: false,
    isLoading: false,
    selectedQuiz: {},
    quizArticle: "",
    editQuestionID: "",
    userSelection: [],
    userWithSubmissions: [],
    prompt: "",
    isGenerating: false,
  } as QuizManagerState,
  reducers: {
    addNewQuiz: (state, action) => {
      state.quizzes = state.quizzes.concat(action.payload)
    },
    removeQuizRedux: (state, action) => {
      state.quizzes = state.quizzes.filter((e) => e.id !== action.payload)
    },
    setSelectedQuiz: (state, action) => {
      state.selectedQuiz = action.payload
    },
    setQuizArticle: (state, action) => {
      state.quizArticle = action.payload
    },
    addNewQuestion: (state, action) => {
      if (!isEmpty(state.selectedQuiz)) {
        let newObj = { ...state.selectedQuiz }
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        newObj.questions = newObj.questions.concat(action.payload)

        state.selectedQuiz = newObj
      }
    },
    updateQuestion: (state, action) => {
      if (!isEmpty(state.selectedQuiz)) {
        let newObj = { ...state.selectedQuiz }
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        let questionIndex = newObj.questions.findIndex(
          (e) => e.id === action.payload.id
        )
        if (questionIndex !== -1) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          newObj.questions[questionIndex] = action.payload
        }
        state.selectedQuiz = newObj
      }
    },
    removeQuestion: (state, action) => {
      let newObj = { ...state.selectedQuiz }
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      let newQuestions = newObj.questions.filter(
        (e) => e.id !== action.payload.id
      )
      newObj.questions = newQuestions
      state.selectedQuiz = newObj
    },
    setEditQuestionID: (state, action) => {
      state.editQuestionID = action.payload
    },
    reorderQuestion: (state, action) => {
      console.log(action.payload)
      const { oldPos, newPos } = action.payload

      let newObj = { ...state.selectedQuiz }
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      let newQuestions: Question[] = newObj.questions as Question[]

      if (
        oldPos < 0 ||
        oldPos >= newQuestions.length ||
        newPos < 0 ||
        newPos >= newQuestions.length
      ) {
        console.log("Invalid indices provided.")
        return
      }

      const [movedElement] = newQuestions.splice(oldPos, 1)
      newQuestions.splice(newPos, 0, movedElement)
      newObj.questions = newQuestions
      state.selectedQuiz = newObj
    },
    setUserSelection: (state, action) => {
      state.userSelection = action.payload
    },
    setUserWithSubmissions: (state, action) => {
      state.userWithSubmissions = action.payload
    },
    setPrompt: (state, action) => {
      state.prompt = action.payload
    },
    setIsGenerating: (state, action) => {
      state.isGenerating = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCreatedQuizzesThunk.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getCreatedQuizzesThunk.fulfilled, (state, action) => {
        if (action.payload.success) {
          state.quizzes = action.payload.data
          state.hasMore = action.payload.hasMore
        }
        state.isLoading = false
      })
      .addCase(getCreatedQuizzesThunk.rejected, (state) => {
        state.isLoading = false
      })
  },
})

export const {
  addNewQuiz,
  removeQuizRedux,
  updateQuestion,
  removeQuestion,
  reorderQuestion,
  setSelectedQuiz,
  addNewQuestion,
  setEditQuestionID,
  setUserSelection,
  setUserWithSubmissions,
  setPrompt,
  setIsGenerating,
  setQuizArticle,
} = quizManagementSlice.actions

export default quizManagementSlice.reducer
