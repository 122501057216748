import React from "react"
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import classes from "./DivContentLoader.module.css"

interface PropsType {
  r: number | string
  w: number | string
  h: number | string
}

const DivContentLoader: React.FC<PropsType> = ({ r, w, h }) => {
  return (
    <div
      className={classes.animatedBackground}
      style={{
        borderRadius: typeof r !== "undefined" ? r : 0,
        width: typeof w !== "undefined" ? w : 100,
        height: typeof h !== "undefined" ? h : 100,
      }}></div>
  )
}
export default DivContentLoader
