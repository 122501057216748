import { v4 as uuidv4 } from "uuid"
import {
  chatGPTFB,
  chatGPTMC,
  ChatGPTQuestion,
  FBDefault,
  MCDefault,
  Question,
} from "../type/Question"

const convertOptionID = (q: chatGPTMC): chatGPTMC => {
  const updatedOptions = q.options.map((option) => {
    return {
      ...option,
      value: uuidv4(),
    }
  })

  const updatedAnswer = q.answer.map((e) => updatedOptions[parseInt(e)].value)

  return {
    ...q,
    options: updatedOptions,
    answer: updatedAnswer,
  }
}

export const convertGeneratedMCQuestion = (q: chatGPTMC): Question => {
  return {
    ...MCDefault,
    ...convertOptionID(q),
    createDate: new Date(),
  } as Question
}

export const convertGeneratedFBQuestion = (q: chatGPTFB): Question => {
  return {
    ...FBDefault,
    ...q,
    createDate: new Date(),
  } as Question
}
