import {
  collection,
  getDocs,
  getFirestore,
  limit,
  orderBy,
  query,
} from "firebase/firestore"
import { ChatMessage } from "../type/ChatMessage"
import firebaseApp from "../config/firebase"
import { getAPIPath, returnIdTokenResult } from "../utils/utiltyHelper"

const db = getFirestore(firebaseApp)

/**
 * get assistant Chatroom by chatroom ID
 * @param {string} id - chatroom ID
 */

export const getAssistantChat = async (
  chatroomID: string
): Promise<
  | {
      success: true
      data: ChatMessage[]
    }
  | {
      success: false
    }
> => {
  return new Promise(async (resolve) => {
    let dbRef = query(
      collection(db, "ChatRoom", chatroomID, "Message"),
      orderBy("createDate", "desc")
    )

    await getDocs(query(dbRef, limit(25)))
      .then((docs) => {
        if (docs && !docs.empty) {
          const resultMessage: ChatMessage[] = docs.docs.map(
            (doc) =>
              ({
                ...doc.data(),
                id: doc.id,
              } as ChatMessage)
          )
          let newAllMessage: ChatMessage[] = []

          resultMessage.forEach((msg) => {
            newAllMessage.push(msg)
          })

          newAllMessage = newAllMessage.slice().reverse()

          resolve({ success: true, data: [...newAllMessage] })
        } else {
          resolve({ success: false })
        }
      })
      .catch((e) => {
        console.log("error", e)
      })
  })
}

/**
 * Ask chatgpt for question suggestion
 */
export enum GENERATIVE_TYPE {
  GENERATE_ARTICLE = "0",
  GENERATE_GRAMMAR_EXERCISE = "1",
  GENERATE_ARTICLE_META = "2",
  GENERATE_READING_COMPREHENSION_EXERCISE = "3",
  GENERATE_DICTIONARY = "4",
  GENERATE_WORD_FORM_EXERCISE = "5",
  GENERATE_TITLE_SUMMARY = "6",
  GENERATE_READING_COMPREHENSION_EXERCISE_TFNG = "7",
  GENERATE_GRAMMAR_EXERCISE_FILL_IN_THE_BLANKS = "8",
}

export const getGeneratedQuestion = async (
  type: GENERATIVE_TYPE,
  question: string
): Promise<{ success: true; data: any } | { success: false }> => {
  return new Promise((resolve, reject) => {
    returnIdTokenResult().then(async (res: any) => {
      await fetch(getAPIPath("/api/openai/chatgpt"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          idToken: res.token,
        },
        body: JSON.stringify({
          type: type,
          question: question,
        }),
      })
        .then((res) => res.json())
        .then((response) => {
          if (response.success) {
            return resolve(response)
          }
          return resolve({ success: false })
        })
        .catch((err) => {
          console.log(err)
          return resolve({
            success: false,
          })
        })
    })
  })
}
