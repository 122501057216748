import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import OrgUserReviewCard from "../Cards/OrgUserReviewCard"
import { Trans } from "react-i18next"

interface PropsType {
  closeModal: () => void
}

const ViewOrgUserModal: React.FC<PropsType> = ({ closeModal }) => {
  const dispatch = useDispatch()
  let history = useNavigate()

  const { selectedUser } = useSelector((state: any) => state.orgUsers)

  return (
    <>
      <div className="grow h-full overflow-hidden">
        <OrgUserReviewCard isCard={false} user={selectedUser} />
      </div>
      <div className="modal-action">
        <button className="btn btn-ghost" onClick={() => closeModal()}>
          <Trans>ViewOrgUserModal.cancel</Trans>
        </button>
      </div>
    </>
  )
}

export default ViewOrgUserModal
