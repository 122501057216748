import React from "react"
import { ARTICLE_ADD_ON } from "../../type/Article"
import { useDispatch, useSelector } from "react-redux"
import Dictionary from "../Dictionary/Dictionary"
import WordLevelSelection from "../WordLevelSelection"
import DifficultWordsList from "../DifficultWordsList"
import GoodSentencesList from "../GoodSentencesList"

interface PropsType {
  closeModal: () => void
  extraObject: {
    type: ARTICLE_ADD_ON
  }
}

const MobileArticleHelperModel: React.FC<PropsType> = ({
  closeModal,
  extraObject,
}) => {
  return (
    <>
      <div className="w-full h-full ">
        {extraObject.type === ARTICLE_ADD_ON.DICTIONARY && <Dictionary />}
        {extraObject.type === ARTICLE_ADD_ON.WORD_LEVEL && (
          <WordLevelSelection useCollapse={false} />
        )}
        {extraObject.type === ARTICLE_ADD_ON.DIFFICULT_WORD && (
          <DifficultWordsList useCollapse={false} />
        )}
        {extraObject.type === ARTICLE_ADD_ON.GOOD_SENTENCE && (
          <GoodSentencesList useCollapse={false} />
        )}
      </div>
    </>
  )
}
export default MobileArticleHelperModel
