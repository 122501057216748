import React from "react"
import { faChevronLeft, faUser } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useNavigate } from "react-router-dom"
import { useSelector } from "react-redux"

interface PropsType {
  children: JSX.Element | JSX.Element[]
  title?: string
}

const PageHOC: React.FC<PropsType> = ({ children, title }) => {
  let history = useNavigate()
  const { pageTitle } = useSelector((state: any) => state.header)

  return (
    <div>
      {/*  Nav*/}
      <div className="navbar  flex justify-between bg-base-100  z-30 shadow-md ">
        <div className="flex-none">
          <button
            className="btn btn-square btn-ghost"
            onClick={() => history(-1)}>
            <FontAwesomeIcon
              icon={faChevronLeft}
              className="text-gray-700 font-bold my-auto"
            />
          </button>
        </div>
        <div className="text-gray-700 font-bold text-xl flex-1">
          {title ? title : pageTitle}
        </div>
      </div>

      {/*    Main*/}
      <div className="pt-4 max-w-5xl mx-auto px-4">{children}</div>
    </div>
  )
}
export default PageHOC
