import React, { useState } from "react"
import InformationCircleIcon from "@heroicons/react/24/outline/InformationCircleIcon"
import classNames from "classnames"

export enum SelectSize {
  SM,
  MD,
  LG,
  XL,
}

export interface SelectOption {
  label: string
  value?: string
  count?: number
}

export const defaultSelectOption = {
  label: "new folder",
  value: "newfolder",
}

export interface SelectBoxProps {
  labelTitle?: string
  labelDescription?: string
  defaultValue?: SelectOption[]
  containerStyle?: string
  placeholder?: string
  labelStyle?: string
  options: SelectOption[]
  updateType: string
  updateFormValue: (data: { value: string; updateType: string }) => void
  size?: SelectSize
}

const SelectBox: React.FC<SelectBoxProps> = (props) => {
  const {
    labelTitle,
    labelDescription,
    defaultValue,
    containerStyle,
    placeholder,
    labelStyle,
    options,
    updateType,
    updateFormValue,
    size,
  } = props

  const [value, setValue] = useState(defaultValue || "")

  const updateValue = (newValue: string) => {
    updateFormValue({ updateType, value: newValue })
    setValue(newValue)
  }

  return (
    <div className={`inline-block w-full ${containerStyle}`}>
      {labelTitle && (
        <label className={`label  ${labelStyle}`}>
          <div className="label-text">
            {labelTitle}
            {labelDescription && (
              <div
                className="tooltip tooltip-right"
                data-tip={labelDescription}>
                <InformationCircleIcon className="w-4 h-4" />
              </div>
            )}
          </div>
        </label>
      )}

      <select
        className={classNames("select select-bordered w-full", {
          "select-sm ": size === SelectSize.SM,
          "select-md ": size === SelectSize.MD,
          "select-lg ": size === SelectSize.LG,
          "text-2xl": size === SelectSize.XL,
        })}
        value={value as string}
        onChange={(e) => updateValue(e.target.value)}>
        <option disabled={true} value="PLACEHOLDER">
          {placeholder}
        </option>
        {options.map((o, k) => {
          return (
            <option value={o.value || o.label} key={k}>
              {o.label}
            </option>
          )
        })}
      </select>
    </div>
  )
}

export default SelectBox
