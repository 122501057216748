import React, { useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { defaultQuiz, Quiz, QuizType } from "../../type/Quiz"
import { t } from "i18next"
import InputText, { InputSize, InputUpdateType } from "../Input/InputText"
import { v4 as uuidv4 } from "uuid"
import { addQuiz, removeQuiz } from "../../helperFunction/quizDBHelper"
import { addNewQuiz, removeQuizRedux } from "../../redux/quizManagementSlice"
import { showNotification } from "../../redux/headerSlice"
import { Trans } from "react-i18next"
import { useNavigate } from "react-router-dom"

interface PropsType {
  closeModal: () => void
  extraObject: {
    quizID: string
  }
}

const RemoveQuizModalBody: React.FC<PropsType> = ({
  closeModal,
  extraObject,
}) => {
  const dispatch = useDispatch()
  let history = useNavigate()

  const confirmRemoveQuiz = () => {
    removeQuiz(extraObject.quizID).then((result) => {
      if (result.success) {
        dispatch(removeQuizRedux(extraObject.quizID))
        dispatch(
          showNotification({
            message: "Remove Quiz Successfully!",
            status: 1,
          })
        )
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        dispatch(closeModal())
        history("/management/quiz")
      }
    })
  }

  return (
    <>
      <p>
        <Trans>RemoveQuizModal.remove-title</Trans>
      </p>

      <div className="modal-action">
        <button className="btn btn-ghost" onClick={() => closeModal()}>
          <Trans>RemoveQuizModal.cancel</Trans>
        </button>
        <button
          className="btn text-white btn-primary px-6"
          onClick={confirmRemoveQuiz}>
          <Trans>RemoveQuizModal.remove</Trans>
        </button>
      </div>
    </>
  )
}

export default RemoveQuizModalBody
