import React, { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import InputText, {
  InputSize,
  InputUpdateType,
} from "../../components/Input/InputText"
import { showNotification } from "../../redux/headerSlice"
import { setSelectedText } from "../../redux/toolsSlice"
import { addWordToCollected } from "../../redux/userSlice"
import Dictionary from "../../components/Dictionary/Dictionary"

import SelectBox, {
  SelectOption,
  SelectSize,
} from "../../components/Input/SelectBox"
import { defaultWordObject, PART_OF_SPEECH, WordObject } from "../../type/User"
import { Meaning } from "../../type/Dictionary"
import { collectedToSelectOptions } from "../../helperFunction/bookmarkHelper"
import { t } from "i18next"
import { Trans } from "react-i18next"

interface PropsType {
  closeModal: () => void
  extraObject: {
    selectedText: string
  }
}

const AddNewWordModalBody: React.FC<PropsType> = ({
  closeModal,
  extraObject,
}) => {
  const dispatch = useDispatch()
  const dictionaryRef = useRef<any | null>(null)
  const { isLoading: isLoading, collected } = useSelector(
    (state: any) => state.user
  )

  const [word, setWord] = useState<string>(
    extraObject.selectedText ? extraObject.selectedText : ""
  )
  const [folderName, setFolderName] = useState<string>("new folder")
  const [folderNames, setFolderNames] = useState<SelectOption[]>([])

  const [isNewFolder, setIsNewFolder] = useState<boolean>(false)

  useEffect(() => {
    if (!isLoading && collected) {
      const options: SelectOption[] = collectedToSelectOptions(collected)
      setFolderNames(options)
      console.log(options)
      if (options.length > 0) {
        setFolderName(options[0].label)
      } else {
        setFolderName("new folder")
      }
    }
  }, [isLoading, collected])

  const saveNewWord = () => {
    const obj: { wordCHI: string; partOfSpeech: PART_OF_SPEECH[] } =
      dictionaryRef.current.returnData()

    const newWordObject: WordObject = {
      ...defaultWordObject,
      name: word,
      folderName: folderName.replace(/\s+$/, ""),
      meanings: obj.wordCHI,
      partOfSpeech: obj.partOfSpeech,
    }

    dispatch(addWordToCollected(newWordObject))
    dispatch(
      showNotification({
        message: t("AddNewWordModal.new-word-added"),
        status: 1,
      })
    )
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(closeModal())
  }

  const updateFormValue = ({ updateType, value }: InputUpdateType) => {
    if (updateType === "word") {
      setWord(value)
    } else {
      setFolderName(value)
    }
  }

  return (
    <>
      <div className="w-full h-full overflow-y-scroll md:overflow-y-hidden md:grid grid-cols-2 gap-4">
        <div className="col-span-1">
          <InputText
            type="text"
            defaultValue={word}
            updateType="word"
            containerStyle="mt-4"
            labelTitle={t("AddNewWordModal.new-word")}
            updateFormValue={updateFormValue}
            onTimeout={(str: string) => dispatch(setSelectedText(str))}
            size={InputSize.LG}
            noSpecialChar={true}
          />

          {isNewFolder ? (
            <>
              <InputText
                type="text"
                defaultValue={word}
                updateType="folderName"
                containerStyle="mt-2"
                labelTitle={t("AddNewWordModal.new-collection")}
                placeholder={t("AddNewWordModal.name") as string}
                updateFormValue={updateFormValue}
                onTimeout={(str: string) => dispatch(setSelectedText(str))}
                size={InputSize.LG}
                noSpecialChar={true}
              />
              <button
                className="btn w-full mt-2"
                onClick={() => setIsNewFolder(false)}>
                <Trans>AddNewWordModal.use-my-collection</Trans>
              </button>
            </>
          ) : (
            <>
              <SelectBox
                labelTitle={t("AddNewWordModal.my-collection") as string}
                options={folderNames}
                updateType={"folderName"}
                updateFormValue={updateFormValue}
                size={SelectSize.LG}
                containerStyle="my-2"
              />
              <button
                className="btn w-full mt-2"
                onClick={() => setIsNewFolder(true)}>
                <Trans>AddNewWordModal.new-collection</Trans>
              </button>
            </>
          )}
        </div>
        <div className="col-span-1">
          <div className="lg:border lg:rounded-xl lg:shadow lg:p-4 my-4">
            <Dictionary selectable={true} ref={dictionaryRef} />
          </div>
        </div>
      </div>

      <div className="modal-action">
        <button className="btn btn-ghost" onClick={() => closeModal()}>
          <Trans>AddNewWordModal.cancel</Trans>
        </button>
        <button
          className="btn text-white btn-primary px-6"
          onClick={() => saveNewWord()}>
          <Trans>AddNewWordModal.save</Trans>
        </button>
      </div>
    </>
  )
}

export default AddNewWordModalBody
