import { ROLE } from "../enum/APP_TYPE"
import { DateObj } from "./SystemType"
import { Meaning } from "./Dictionary"
import { ARTICLE_LEVEL } from "./Article"

export interface OrgStudentUser {
  id: string
  email: string
  className: string
  classNo: string
  firstName: string
  lastName: string
  userName: string
  lastLogin: Date | DateObj
  createDate: Date
  // e.g. look4kol
  orgID: string
  // e.g. 2223/1A
  orgGroups: string[]
  role: ROLE.ORG_USER
}

export interface TeacherUser {
  id: string
  email: string
  firstName: string
  lastName: string
  lastLogin: Date | DateObj
  createDate: Date | DateObj
  orgID: string
  role: ROLE.ORG_ADMIN
}

export type User = OrgStudentUser | TeacherUser

export enum PART_OF_SPEECH {
  VERB = "verb",
  NOUN = "noun",
  ADJECTIVE = "adjective",
  ADVERB = "adverb",
  PRONOUN = "pronoun",
  INTERJECTION = "interjection",
  CONJUNCTION = "conjunction",
  PREPOSITION = "preposition",
  UNKNOWN = "unknown",
}

export const colorCodes = {
  [PART_OF_SPEECH.VERB]: "rgba(255, 99, 132, 0.8)",
  [PART_OF_SPEECH.NOUN]: "rgba(54, 162, 235, 0.8)",
  [PART_OF_SPEECH.ADJECTIVE]: "rgba(255, 206, 86, 0.8)",
  [PART_OF_SPEECH.ADVERB]: "rgba(75, 192, 192, 0.8)",
  [PART_OF_SPEECH.PRONOUN]: "rgba(153, 102, 255, 0.8)",
  [PART_OF_SPEECH.INTERJECTION]: "rgba(255, 159, 64, 0.8)",
  [PART_OF_SPEECH.CONJUNCTION]: "rgba(255, 99, 132, 1)",
  [PART_OF_SPEECH.PREPOSITION]: "rgba(99, 255, 211, 1)",
}

export interface Folder {
  name: string
  count: number
}

export interface ArticleReadHistory {
  articleTitle: string
  createDate: Date
  difficult: ARTICLE_LEVEL
}

export const defaultArticleReadHistory: ArticleReadHistory = {
  articleTitle: "",
  createDate: new Date(),
  difficult: ARTICLE_LEVEL.EASY,
}

export interface WordObject {
  id?: string
  docID: string
  name: string
  createDate: Date
  // the string "-1" is mean user removed this word
  lastAccess: Date | "-1"
  folderName: string
  meanings: string
  partOfSpeech: PART_OF_SPEECH[]
}

export const defaultWordObject: WordObject = {
  id: "",
  docID: "",
  name: "",
  createDate: new Date(),
  lastAccess: new Date(),
  folderName: "",
  meanings: "",
  partOfSpeech: [PART_OF_SPEECH.UNKNOWN],
}

export interface UserCollectionWord {
  name: string
  createDate: Date
  // the string "-1" is mean user removed this word
  lastAccess: Date | "-1"
  folderName: string
  meaning: object
  partOfSpeech: PART_OF_SPEECH
}

export interface UserCollection {
  id: string
  createDate: Date
  collected: UserCollectionWord[]
  lastUpdate: Date | DateObj
  createUserID: string
  isFull: boolean
}

export interface LocalUserCollection {
  docID: string
  record: WordObject[]
}

export const defaultOrgStudentUser: OrgStudentUser = {
  id: "",
  email: "",
  className: "",
  classNo: "",
  firstName: "",
  lastName: "",
  userName: "",
  lastLogin: new Date(),
  createDate: new Date(),
  orgID: "",
  orgGroups: [],
  role: ROLE.ORG_USER,
}

export const exampleOrgStudentUser = {
  id: "123",
  email: "wongkaiyinhk@gamil.com",
  className: "1A",
  classNo: "22",
  firstName: "Kai Yin",
  lastName: "WONG",
  userName: "momowong",
  lastLogin: new Date(),
  createDate: new Date(),
  orgID: "look4kol",
  orgGroups: [
    "8d666bdf-7f7b-43db-835a-ae3b4a34cc57",
    "8d666bdf-7f7b-43db-835a-ae3b4a34cc12",
  ],
  role: ROLE.ORG_USER,
}
