import React, { useEffect, useImperativeHandle, useState } from "react"
import { Article, ARTICLE_LEVEL } from "../../type/Article"
import classNames from "classnames"

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import classes from "../../utils/articleStyle.module.css"
import TitleCard from "../Cards/TitleCard"
import { getArticle } from "../../helperFunction/articleDBHelper"
import { openModal } from "../../redux/modalSlice"
import { MODAL_BODY_TYPES } from "../../utils/globalConstantUtil"
import { ModalSize } from "../../containers/ModalLayout"
import { useDispatch, useSelector } from "react-redux"

interface PropsType {
  data?: Article
  articleID?: string
}

const AdminArticleCard: React.FC<PropsType> = (
  { data, articleID },
  ref: any
) => {
  const dispatch = useDispatch()
  const { selectedQuiz } = useSelector((state: any) => state.quizMarker)

  const [difficult, setDifficult] = useState<ARTICLE_LEVEL>(ARTICLE_LEVEL.EASY)
  const [article, setArticle] = useState<Article | undefined>(
    data ? data : undefined
  )

  useImperativeHandle(ref, () => ({
    returnData() {
      return {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        id: article.id as string,
        difficult: difficult,
      }
    },
  }))

  useEffect(() => {
    if (data) {
      setArticle(data)
    }
  }, [data])

  useEffect(() => {
    if (articleID) {
      getArticle(articleID).then((result) => {
        if (result.success) {
          setArticle(result.data)
        }
      })
    }
  }, [articleID])

  useEffect(() => {
    setDifficult(
      selectedQuiz.articleLevel ? selectedQuiz.articleLevel : ARTICLE_LEVEL.EASY
    )
  }, [selectedQuiz.articleLevel])

  const selectArticle = () => {
    dispatch(
      openModal({
        title: "Select Article",
        bodyType: MODAL_BODY_TYPES.SELECT_ARTICLE,
        size: ModalSize.XL,
      })
    )
  }

  if (article) {
    return (
      <div className="my-2 h-full overflow-hidden">
        <TitleCard title={article.title} topMargin="mt-0">
          <div className="relative">
            {/*  tab*/}
            <div className="w-full bg-white sticky py-2 -top-2">
              <div className="relative tabs tabs-boxed">
                <a
                  className={classNames("tab tab-md", {
                    "tab-active": difficult === ARTICLE_LEVEL.EASY,
                  })}
                  onClick={() => {
                    if (!selectedQuiz.isPublish) {
                      setDifficult(ARTICLE_LEVEL.EASY)
                    }
                  }}>
                  Easy
                </a>
                <a
                  className={classNames("tab tab-md", {
                    "tab-active": difficult === ARTICLE_LEVEL.MEDIUM,
                  })}
                  onClick={() => {
                    if (!selectedQuiz.isPublish) {
                      setDifficult(ARTICLE_LEVEL.MEDIUM)
                    }
                  }}>
                  Medium
                </a>
                <a
                  className={classNames("tab tab-md", {
                    "tab-active": difficult === ARTICLE_LEVEL.HARD,
                  })}
                  onClick={() => {
                    if (!selectedQuiz.isPublish) {
                      setDifficult(ARTICLE_LEVEL.HARD)
                    }
                  }}>
                  Hard
                </a>

                {!selectedQuiz.isPublish && (
                  <button
                    className="absolute right-0 btn btn-sm btn-secondary text-right"
                    disabled={selectedQuiz.isPublish}
                    onClick={() => selectArticle()}>
                    Change article
                  </button>
                )}
              </div>
            </div>
            {/*Main Content*/}
            <div
              className={classNames(
                "overflow-hidden mb-4",
                classes.ReactQuillDisplay
              )}>
              {difficult === ARTICLE_LEVEL.EASY && (
                <div>{article.easy.article}</div>
              )}
              {difficult === ARTICLE_LEVEL.MEDIUM && (
                <div>{article.medium.article} </div>
              )}
              {difficult === ARTICLE_LEVEL.HARD && (
                <div>{article.hard.article}</div>
              )}
            </div>
          </div>
        </TitleCard>
      </div>
    )
  }
  return <div>loading</div>
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export default React.forwardRef(AdminArticleCard)
