import React from "react"
import { Meaning, Phonetic } from "../../type/Dictionary"

interface PropsType {
  word: string
  wordCHI: string
  phonetics: Phonetic[]
  meanings: Meaning[]
  setText: (str: string) => void
}

const Result: React.FC<PropsType> = ({
  word,
  wordCHI,
  phonetics,
  meanings,
  setText,
}) => {
  return (
    <ul className="pb-14">
      <li className="text-2xl my-2">{wordCHI}</li>

      <li className="word">
        {phonetics.map((phonetic, index) => (
          <span className="italic text-sm text-gray-400" key={index}>
            {phonetic.text}
          </span>
        ))}
      </li>

      {meanings.map((meaning, index) => {
        return (
          <li className="my-4" key={"li" + index}>
            <h3 className="font-bold uppercase">{meaning.partOfSpeech}</h3>
            <section className=" ">
              <span className="badge badge-primary">Meaning</span>
              {meaning.definitions.map((definition, index) => {
                if (index < 2) {
                  return (
                    <p className="text-gray-500 text-sm ml-1" key={"p" + index}>
                      - {definition.definition}
                    </p>
                  )
                }
              })}
            </section>

            {meaning.synonyms.length !== 0 && (
              <section className="">
                <span className="badge badge-secondary">Synonyms</span>
                {meaning.synonyms.map((synonym, index) => {
                  if (index < 2) {
                    return (
                      <span
                        className="text-gray-500 text-sm ml-1"
                        key={"span" + index}
                        onClick={() => setText(synonym)}>
                        {`${synonym}, `}
                      </span>
                    )
                  }
                })}
              </section>
            )}
          </li>
        )
      })}
    </ul>
  )
}
export default Result
