import {
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  limit,
  orderBy,
  query,
  where,
  getCountFromServer,
  updateDoc,
} from "firebase/firestore"
import firebaseApp from "../config/firebase"
import { User } from "../type/User"
import { Organization } from "../type/Organization"
import { ROLE } from "../enum/APP_TYPE"
import { timeConverter } from "../utils/utiltyHelper"
import { DateObj } from "../type/SystemType"

const db = getFirestore(firebaseApp)

const getOrgProfile = (
  orgID: string
): Promise<
  | {
      success: true
      data: Organization
    }
  | {
      success: false
    }
> => {
  return new Promise(async (resolve) => {
    await getDoc(doc(db, "Organization", orgID))
      .then((doc) => {
        if (doc.exists()) {
          return resolve({
            success: true,
            data: {
              id: doc.id,
              ...doc.data(),
            } as Organization,
          })
        }
        return resolve({
          success: false,
        })
      })
      .catch((err) => {
        console.log(err)
        return resolve({
          success: false,
        })
      })
  })
}

export interface getOrgUsersByOrgIDCondition {
  orgID: "" | string
  orgGroups?: "" | string[]
  startAfter?: "" | DateObj | Date
  endAt?: "" | DateObj | Date
}

const getOrgUsersByOrgID = (
  condition: getOrgUsersByOrgIDCondition
): Promise<
  | {
      success: true
      data: User[]
      hasMore: boolean
    }
  | {
      success: false
    }
> => {
  return new Promise(async (resolve) => {
    let dbRef: any = collection(db, "User")

    dbRef = query(dbRef, where("orgID", "==", condition.orgID))
    dbRef = query(dbRef, where("role", "==", ROLE.ORG_USER))

    if (condition.orgGroups !== "") {
      dbRef = query(
        dbRef,
        where("orgGroups", "array-contains-any", condition.orgGroups)
      )
    }

    if (condition.startAfter !== "") {
      dbRef = query(dbRef, where("email", ">=", condition.startAfter))
    }

    if (condition.endAt !== "") {
      dbRef = query(dbRef, where("email", "<", condition.endAt))
    }

    await getDocs(query(dbRef, limit(200), orderBy("email", "asc")))
      .then((docs: any) => {
        if (!docs.empty) {
          let allUsers: User[] = []
          docs.forEach((doc: any) => {
            allUsers.push({
              id: doc.id,
              ...doc.data(),
            } as User)
          })
          return resolve({
            success: true,
            data: allUsers,
            hasMore: allUsers.length === 10,
          })
        }
        return resolve({
          success: true,
          data: [],
          hasMore: false,
        })
      })
      .catch((err: any) => {
        console.log(err)
        return resolve({
          success: false,
        })
      })
  })
}

const updateOrg = (
  orgID: string,
  data: Organization
): Promise<{
  success: boolean
}> => {
  return new Promise(async (resolve) => {
    // Destructure and remove 'id' field
    const { id, ...dataWithoutId } = data
    await updateDoc(doc(db, "Organization", orgID), {
      // Use the object without 'id' field for updating
      ...dataWithoutId,
      createDate: timeConverter(data.createDate),
      subscriptionStart: timeConverter(data.subscriptionStart),
      subscriptionEnd: timeConverter(data.subscriptionEnd),
    })
      .then(() => {
        return resolve({
          success: true,
        })
      })
      .catch((err: any) => {
        return resolve({
          success: false,
        })
      })
  })
}

export { getOrgProfile, getOrgUsersByOrgID, updateOrg }
