const pickRandomItems = (arr: object[], n: number): object[] => {
  const result: object[] = []
  const length = arr.length

  // If 'n' is greater than the length of the array, return the whole array
  if (n >= length) {
    return arr
  }

  // Randomly pick 'n' items
  while (result.length < n) {
    const randomIndex = Math.floor(Math.random() * length)
    const randomItem = arr[randomIndex]

    // Avoid duplicates
    if (!result.includes(randomItem)) {
      result.push(randomItem)
    }
  }

  return result
}
export { pickRandomItems }
