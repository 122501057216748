import { createSlice } from "@reduxjs/toolkit"
import { ROLE } from "../enum/APP_TYPE"

export interface AUTH {
  uid: string
  photoURL: string | null
  displayName: string | null
  email: string | null
  token?: string | null
  role: ROLE | ""
  expirationTime: Date | null
  custID?: string
  orgID?: string
}

const defaultAuth: AUTH = {
  uid: "",
  photoURL: "",
  displayName: "",
  email: "",
  token: "",
  role: "",
  expirationTime: null,
  custID: "",
  orgID: "",
}

export const firebaseSlice = createSlice({
  name: "firebase",
  initialState: {
    auth: defaultAuth,
  },
  reducers: {
    setLogin: (state, action) => {
      state.auth = action.payload
    },

    setLogout: (state, action) => {
      state.auth = defaultAuth
    },
  },
})

export const { setLogin, setLogout } = firebaseSlice.actions

export default firebaseSlice.reducer
