import React, { useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { defaultQuiz, Quiz, QuizType } from "../../type/Quiz"
import { t } from "i18next"
import InputText, { InputSize, InputUpdateType } from "../Input/InputText"
import { v4 as uuidv4 } from "uuid"
import { addQuiz } from "../../helperFunction/quizDBHelper"
import { addNewQuiz } from "../../redux/quizManagementSlice"
import { showNotification } from "../../redux/headerSlice"
import { Trans } from "react-i18next"
import { useNavigate } from "react-router-dom"

interface PropsType {
  closeModal: () => void
  extraObject: {
    quiz: Quiz
  }
}

const CloneQuizModalBody: React.FC<PropsType> = ({
  closeModal,
  extraObject,
}) => {
  const dispatch = useDispatch()
  let history = useNavigate()

  const auth = useSelector((state: any) => {
    return state.firebase.auth
  })

  const [newQuiz, setNewQuiz] = useState<Quiz>({
    ...extraObject.quiz,
    id: uuidv4(),
    isPublish: false,
    title: extraObject.quiz.title + "-copy",
  })

  const saveNewQuiz = () => {
    addQuiz(newQuiz).then((result) => {
      if (result.success) {
        dispatch(addNewQuiz(newQuiz))
        dispatch(
          showNotification({
            message: "New Quiz Added!",
            status: 1,
          })
        )
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        dispatch(closeModal())
        history(`/management/quiz/${newQuiz.id}`)
      }
    })
  }

  const updateFormValue = ({ updateType, value }: InputUpdateType) => {
    setNewQuiz({
      ...newQuiz,
      [updateType]: value,
    })
  }

  return (
    <>
      <>
        <InputText
          type="text"
          defaultValue={newQuiz.title}
          updateType="title"
          containerStyle="mt-4"
          labelTitle={t("CloneQuizModal.quiz-title")}
          updateFormValue={updateFormValue}
          size={InputSize.MD}
        />
      </>

      <div className="modal-action">
        <button className="btn btn-ghost" onClick={() => closeModal()}>
          <Trans>CloneQuizModal.cancel</Trans>
        </button>
        <button
          className="btn text-white btn-primary px-6"
          onClick={saveNewQuiz}>
          <Trans>CloneQuizModal.create</Trans>
        </button>
      </div>
    </>
  )
}

export default CloneQuizModalBody
