import { OrganizationGroup } from "../type/Organization"
import { SelectOption } from "../components/Input/SelectBox"

const orgGroupIDToSelectOptions = (
  allGroups: OrganizationGroup[],
  groupIDs: string[]
): SelectOption[] => {
  try {
    return allGroups
      .filter((group) => groupIDs.includes(group.id))
      .map((group) => ({
        label: group.groupName,
        value: group.id,
      }))
  } catch (e) {
    return []
  }
}

const orgGroupIDToName = (allGroups: OrganizationGroup[], groupID: string) => {
  try {
    return allGroups.find((group) => group.id === groupID)?.groupName
  } catch (e) {
    return "unknown group"
  }
}

export { orgGroupIDToSelectOptions, orgGroupIDToName }
