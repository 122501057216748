import React from "react"
import InformationCircleIcon from "@heroicons/react/24/outline/InformationCircleIcon"
import Select, { components } from "react-select"
import classNames from "classnames"
import { SelectBoxProps, SelectOption, SelectSize } from "./SelectBox"

const MultiValueLabel = (props: any) => {
  return <components.MultiValueLabel {...props} title={props.data.name} />
}

const MultiSelectBox: React.FC<SelectBoxProps> = (props) => {
  const {
    labelTitle,
    labelDescription,
    defaultValue,
    containerStyle,
    placeholder,
    labelStyle,
    options,
    updateType,
    updateFormValue,
    size,
  } = props

  const [value, setValue] = React.useState<SelectOption[]>(
    defaultValue ? defaultValue : []
  )

  const updateValue = (newValue: SelectOption[]) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    updateFormValue({ updateType, value: newValue.map((o) => o.value || "") })
    setValue(newValue)
  }

  return (
    <div className={`inline-block w-full my-2 ${containerStyle}`}>
      <label className={`label  ${labelStyle}`}>
        <div className="label-text">
          {labelTitle}
          {labelDescription && (
            <div className="tooltip tooltip-right" data-tip={labelDescription}>
              <InformationCircleIcon className="w-4 h-4" />
            </div>
          )}
        </div>
      </label>

      <Select
        className={classNames(" w-full text-lg rounded-2xl py-2", {})}
        value={value}
        options={options}
        isMulti
        components={{ MultiValueLabel }}
        onChange={(selectedOptions) =>
          updateValue(selectedOptions as SelectOption[])
        }
        placeholder={placeholder}
      />
    </div>
  )
}

export default MultiSelectBox
