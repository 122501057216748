import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { Quiz } from "../type/Quiz"
import { objectDateToString } from "../utils/utiltyHelper"
import { getQuiz, getUsersQuizSubmission } from "../helperFunction/quizDBHelper"
import { defaultAnswer, Submission } from "../type/Submission"

export interface SubmissionUpdate {
  questionID: string
  answer: string | string[]
}

const updateUserSubmissionAnswer = (
  quiz: Quiz,
  submission: Submission,
  data: SubmissionUpdate
): Submission => {
  let newQuiz = { ...quiz }
  let newSubmission = { ...submission }

  let index = newSubmission.answers.findIndex(
    (e) => e.questionID === data.questionID
  )
  // const questionType = quiz.questions.find(
  //   (e) => e.id === data.questionID
  // )?.type

  // if found, means user change the answer
  if (index !== -1) {
    newSubmission.answers[index] = {
      ...newSubmission.answers[index],
      answer: data.answer,
    }
    console.log(newSubmission)
    return newSubmission
  }
  newSubmission.answers.push({
    ...defaultAnswer,
    questionID: data.questionID,
    answer: data.answer,
  })
  console.log(newSubmission)
  return newSubmission
}

export interface QuizManagerState {
  quiz: Quiz | object
  isLoading: boolean
  userSubmission: Submission | object
  isLoadedUserAnswer: boolean
}

export const getQuizObj = createAsyncThunk(
  "quiz/getQuiz",
  async (id: string) => {
    return objectDateToString(await getQuiz(id))
  }
)

export const getUserAnswer = createAsyncThunk(
  "quiz/getUserAnswer",
  async ({ id, uid }: { id: string; uid: string }) => {
    return objectDateToString(await getUsersQuizSubmission(id, uid))
  }
)

export const quizSlice = createSlice({
  name: "quiz",
  initialState: {
    quiz: {},
    isLoading: false,
    userSubmission: {},
    isLoadedUserAnswer: false,
  } as QuizManagerState,
  reducers: {
    setQuiz: (state, action) => {
      state.quiz = action.payload
    },
    resetQuiz: (state, action) => {
      state.isLoading = true
      state.quiz = {}
    },
    setUserAnswer: (state, action) => {
      state.userSubmission = action.payload
    },
    updateUserAnswer: (state, action) => {
      state.userSubmission = updateUserSubmissionAnswer(
        state.quiz as Quiz,
        state.userSubmission as Submission,
        action.payload
      )
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getQuizObj.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getQuizObj.fulfilled, (state, action) => {
        state.quiz = action.payload.data
        state.isLoading = false
      })
      .addCase(getQuizObj.rejected, (state) => {
        state.isLoading = false
      })
      //getUserAnswer
      .addCase(getUserAnswer.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getUserAnswer.fulfilled, (state, action) => {
        state.userSubmission = action.payload.data
        state.isLoading = false
        state.isLoadedUserAnswer = true
      })
      .addCase(getUserAnswer.rejected, (state) => {
        state.isLoading = false
      })
  },
})

export const { setQuiz, setUserAnswer, updateUserAnswer } = quizSlice.actions

export default quizSlice.reducer
