import React from "react"
import { colorCodes, UserCollectionWord } from "../../type/User"
import { timeStampToDisplayTimeString } from "../../utils/utiltyHelper"
import { getPartOfSpeechTran } from "../../helperFunction/grammarHelper"
import { useTranslation } from "react-i18next"

interface PropsType {
  data: UserCollectionWord
}

const WordCard: React.FC<PropsType> = ({ data }) => {
  const { t } = useTranslation()
  const renderPartOfSpeech = () => {
    if (Array.isArray(data.partOfSpeech)) {
      return data.partOfSpeech.map((pos, index) => (
        <span
          key={index}
          className="mr-1 px-2 py-1 rounded-lg text-white"
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          style={{ backgroundColor: colorCodes[pos] }}>
          {pos}
        </span>
      ))
    }
    return <div></div>
  }

  return (
    <div className="p-2 w-full inline-block bg-orange-100 hover:bg-orange-100 !active:bg-orange-200 rounded-lg">
      {data.name}
      <div className="block text-sm text-gray-500">
        {timeStampToDisplayTimeString(data.createDate)}
      </div>
      <div className="mt-2">{renderPartOfSpeech()}</div>
    </div>
  )
}

export default WordCard
