import {
  Folder,
  LocalUserCollection,
  PART_OF_SPEECH,
  WordObject,
} from "../type/User"
import { v4 as uuidv4 } from "uuid"
import { SelectOption } from "../components/Input/SelectBox"
import { timeConverter } from "../utils/utiltyHelper"

export const groupWordsByDocID = (
  words: WordObject[]
): LocalUserCollection[] => {
  const groupedResults: Record<string, WordObject[]> = {}
  const unassignedItems: WordObject[] = []

  words.forEach((word) => {
    if (word.docID) {
      if (!groupedResults[word.docID]) {
        groupedResults[word.docID] = []
      }
      groupedResults[word.docID].push(word)
    } else {
      // Create a new object with the same properties
      unassignedItems.push({ ...word })
    }
  })

  unassignedItems.forEach((word, index) => {
    let assigned = false
    for (const docID in groupedResults) {
      if (groupedResults[docID].length < 500) {
        groupedResults[docID].push(word)
        // Update the property of the new object
        unassignedItems[index].docID = docID
        assigned = true
        break
      }
    }

    if (!assigned) {
      const newDocID = uuidv4()
      groupedResults[newDocID] = [word]
      // Update the property of the new object
      unassignedItems[index].docID = newDocID
    }
  })

  return Object.keys(groupedResults).map((docID) => ({
    docID,
    record: groupedResults[docID].map(({ id, ...rest }) => rest),
  }))
}

export const localUserCollectionToWords = (
  list: LocalUserCollection[]
): WordObject[] => {
  let allWordObj: WordObject[] = []
  list.map((e) => {
    e.record.map((w) => {
      allWordObj.push({
        ...w,
        id: uuidv4(),
        docID: e.docID,
      })
    })
  })

  return allWordObj
}

export const collectedToFolders = (collected: WordObject[]): Folder[] => {
  const folderMap = new Map<string, Folder>()

  for (const wordObj of collected) {
    //continue
    const { folderName } = wordObj

    if (folderMap.has(folderName)) {
      const folder = folderMap.get(folderName)
      if (folder && wordObj.lastAccess !== "-1") {
        folder.count++
        folderMap.set(folderName, folder)
      }
    } else {
      folderMap.set(folderName, {
        name: folderName,
        count: wordObj.lastAccess !== "-1" ? 1 : 0,
      })
    }
  }

  return Array.from(folderMap.values())
}

export const collectedToSelectOptions = (
  collected: WordObject[]
): SelectOption[] => {
  const folderMap = new Map<string, SelectOption>()

  for (const wordObj of collected) {
    const { folderName } = wordObj
    if (folderMap.has(folderName)) {
      const option = folderMap.get(folderName)
      if (option) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        option.count++
        folderMap.set(folderName, option)
      }
    } else {
      folderMap.set(folderName, { label: folderName, value: folderName })
    }
  }

  return Array.from(folderMap.values())
}

export const countByPartOfSpeech = (
  arr: WordObject[]
): Record<PART_OF_SPEECH, number> => {
  const partOfSpeechCount: Record<PART_OF_SPEECH, number> = {
    [PART_OF_SPEECH.VERB]: 0,
    [PART_OF_SPEECH.NOUN]: 0,
    [PART_OF_SPEECH.ADJECTIVE]: 0,
    [PART_OF_SPEECH.ADVERB]: 0,
    [PART_OF_SPEECH.PRONOUN]: 0,
    [PART_OF_SPEECH.INTERJECTION]: 0,
    [PART_OF_SPEECH.CONJUNCTION]: 0,
    [PART_OF_SPEECH.PREPOSITION]: 0,
    [PART_OF_SPEECH.UNKNOWN]: 0,
  }

  arr.forEach((word) => {
    if (word.partOfSpeech.length === 0) {
      partOfSpeechCount[PART_OF_SPEECH.UNKNOWN]++
    } else if (Array.isArray(word.partOfSpeech)) {
      word.partOfSpeech.forEach((pos) => {
        if (pos in partOfSpeechCount) {
          partOfSpeechCount[pos]++
        } else {
          partOfSpeechCount[PART_OF_SPEECH.UNKNOWN]++
        }
      })
    }
  })

  return partOfSpeechCount
}

export const countWordWithinPastWeek = (objects: Array<any>): number => {
  // Get the current date and time
  const currentDate: Date = new Date()

  // Subtract 7 days from the current date
  const startDate: Date = new Date()
  startDate.setDate(currentDate.getDate() - 7)

  // Filter the objects where createDate falls within the past week
  const filteredObjects: Array<any> = objects.filter(
    (object) =>
      new Date(object.createDate) >= startDate &&
      new Date(object.createDate) <= currentDate
  )

  return filteredObjects.length
}

export const getCountsWordWithinAPastWeek = (arr: WordObject[]): object => {
  const currentDate = new Date()
  const oneWeekAgo = new Date()
  oneWeekAgo.setDate(currentDate.getDate() - 7)

  const wordCount: { [date: string]: number } = {}

  arr.forEach((word) => {
    const wordCreateDate = timeConverter(word.createDate)
    if (wordCreateDate >= oneWeekAgo && wordCreateDate <= currentDate) {
      const dateKey = wordCreateDate.toISOString().substring(5, 10)
      if (wordCount[dateKey]) {
        wordCount[dateKey] += 1
      } else {
        wordCount[dateKey] = 1
      }
    }
  })

  // Add 0 as a record for dates within the past week with no data
  let date = oneWeekAgo
  const orderedWordCount: { [date: string]: number } = {}

  while (date <= currentDate) {
    const dateKey = date.toISOString().substring(5, 10)
    if (!wordCount[dateKey]) {
      wordCount[dateKey] = 0
    }
    orderedWordCount[dateKey] = wordCount[dateKey]
    date.setDate(date.getDate() + 1)
  }

  return orderedWordCount
}

export const reorderWordByForgettingCurve = (
  unsortedWords: WordObject[]
): WordObject[] => {
  const currentDate = new Date()
  const sortedWords: WordObject[] = []
  // Words with lastAccess more than 7 days ago
  const wordsMoreThan7Days: WordObject[] = unsortedWords.filter((word) => {
    const lastAccessDate = new Date(word.lastAccess)
    const timeDiff = currentDate.getTime() - lastAccessDate.getTime()
    const daysDiff = timeDiff / (1000 * 3600 * 24)
    return daysDiff > 6
  })
  sortedWords.push(...shuffleArray(wordsMoreThan7Days))

  // Words with lastAccess between 3-6 days ago
  const wordsBetween3And6Days: WordObject[] = unsortedWords.filter((word) => {
    const lastAccessDate = new Date(word.lastAccess)
    const timeDiff = currentDate.getTime() - lastAccessDate.getTime()
    const daysDiff = timeDiff / (1000 * 3600 * 24)
    return daysDiff >= 3 && daysDiff <= 6
  })
  sortedWords.push(...shuffleArray(wordsBetween3And6Days))

  // Words with lastAccess within the last 3 days
  const wordsWithin2Days: WordObject[] = unsortedWords.filter((word) => {
    const lastAccessDate = new Date(word.lastAccess)
    const timeDiff = currentDate.getTime() - lastAccessDate.getTime()
    const daysDiff = timeDiff / (1000 * 3600 * 24)
    return daysDiff < 3
  })
  sortedWords.push(...shuffleArray(wordsWithin2Days))
  return sortedWords
}

const shuffleArray = <T>(array: T[]): T[] => {
  const shuffledArray = [...array]
  for (let i = shuffledArray.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1))
    ;[shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]]
  }
  return shuffledArray
}

export const getWordsByFolderName = (
  collected: WordObject[],
  folderName: string
): WordObject[] => {
  return collected.filter((wordObj: WordObject) => {
    //handling archive words
    if (folderName === "archive") {
      return wordObj.lastAccess === "-1"
    }

    return wordObj.folderName === folderName && wordObj.lastAccess !== "-1"
  })
}

export const filteredWordObjects = (arr: WordObject[]) => {
  return arr.filter((wordObject) => {
    const partOfSpeechArray = Array.isArray(wordObject.partOfSpeech)
      ? wordObject.partOfSpeech
      : []
    return (
      partOfSpeechArray.includes(PART_OF_SPEECH.VERB) ||
      partOfSpeechArray.includes(PART_OF_SPEECH.ADJECTIVE)
    )
  })
}
