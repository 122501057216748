import React, { useEffect, useState } from "react"
import { Quiz, QuizType } from "../type/Quiz"
import { isAuth, timeStampToDisplayTimeString } from "../utils/utiltyHelper"
import { orgGroupIDToSelectOptions } from "../helperFunction/organizationHelper"
import { useSelector } from "react-redux"
import { Link } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faBook,
  faCalendarCheck,
  faCalendarXmark,
  faClock,
  faFile,
} from "@fortawesome/free-solid-svg-icons"
import { Trans } from "react-i18next"
import { t } from "i18next"
import {
  getUsersQuizSubmission,
  isUserDoneQuiz,
} from "../helperFunction/quizDBHelper"

interface PropsType {
  data: Quiz
}

const QuizCard: React.FC<PropsType> = ({ data }) => {
  const { isLoading: isOrgLoading, organizationProfile: p } = useSelector(
    (state: any) => state.org
  )

  const auth = useSelector((state: any) => {
    return state.firebase.auth
  })

  const [isDone, setIsDone] = useState<boolean>(false)

  useEffect(() => {
    if (isAuth(auth)) {
      isUserDoneQuiz(data.id as string, auth.uid).then((result) => {
        setIsDone(result.success)
      })
    }
  }, [auth])

  return (
    <Link to={`/app/quiz/${data.id}`}>
      <div className="cursor-pointer card  w-full bg-base-100 shadow-xl">
        <div className="relative w-full h-48 overflow-hidden">
          {isDone && (
            <div className="absolute uppercase z-20 top-5 right-5 rotate-12 text-3xl bg-green-200 text-green-800 py-2 px-3 rounded-2xl">
              <Trans>QuizPage.done</Trans>
            </div>
          )}
          <img
            src="https://images.unsplash.com/photo-1456513080510-7bf3a84b82f8?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&h=200&w=300&q=60"
            alt="Homework assignment"
            className="w-full h-full object-cover rounded-t-xl"
          />
          <div className="absolute top-0 w-full h-full bg-orange-800 rounded-t-xl opacity-40"></div>
          <div className="absolute bottom-2 left-2 text-xl text-white w-full">
            {data.title}

            <div className="w-full block mt-2">
              {orgGroupIDToSelectOptions(p.orgGroups, data.orgGroups).map(
                (e) => {
                  return (
                    <div className="badge badge-primary" key={e.value}>
                      {e.label}
                    </div>
                  )
                }
              )}
            </div>
          </div>
        </div>
        <div className="card-body text-gray-600 text-left">
          <div className="grid" style={{ gridTemplateColumns: "50px auto" }}>
            <FontAwesomeIcon
              icon={faClock}
              className="text-orange-600 text-lg font-bold rounded-full "
            />
            <div>
              {typeof data.timeLimit === "number"
                ? data.timeLimit > 0
                  ? `${data.timeLimit / 1000 / 60}  ${t("QuizPage.minutes")}`
                  : t("QuizPage.no-limit")
                : ""}
            </div>
          </div>

          <div className="grid" style={{ gridTemplateColumns: "50px auto" }}>
            <FontAwesomeIcon
              icon={faCalendarCheck}
              className="text-orange-600 text-lg font-bold rounded-full "
            />
            <div>{timeStampToDisplayTimeString(data.startDate)}</div>
          </div>

          <div className="grid" style={{ gridTemplateColumns: "50px auto" }}>
            <FontAwesomeIcon
              icon={faCalendarXmark}
              className="text-orange-600 text-lg font-bold rounded-full "
            />
            <div>{timeStampToDisplayTimeString(data.endDate)}</div>
          </div>

          <div className="grid" style={{ gridTemplateColumns: "50px auto" }}>
            <FontAwesomeIcon
              icon={faFile}
              className="text-orange-600 text-lg font-bold rounded-full "
            />
            <div>
              {data.questions.length} <Trans>QuizPage.question</Trans>
            </div>
          </div>
        </div>
        <div className="card-actions justify-end"></div>
      </div>
    </Link>
  )
}
export default QuizCard
