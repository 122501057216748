import { createSlice } from "@reduxjs/toolkit"

export const systemSlice = createSlice({
  name: "firebase",
  initialState: {
    screenWidth: 0,
    screenHeight: 0,
    isLocked: false,
    isOpenChatroom: false,
  },
  reducers: {
    setScreenWidth: (state, action) => {
      state.screenWidth = action.payload
    },

    setScreenHeight: (state, action) => {
      state.screenHeight = action.payload
    },

    setIsLocked: (state, action) => {
      state.isLocked = true
    },

    setOpenChatroom: (state, action) => {
      state.isOpenChatroom = action.payload
    },
  },
})

export const { setScreenWidth, setScreenHeight, setIsLocked, setOpenChatroom } =
  systemSlice.actions

export default systemSlice.reducer
