import { createSlice } from "@reduxjs/toolkit"

const returnMyText = (largeText: string) => {
  const sentenceRegex = /^[^.!?]+[.!?]/
  const match = largeText.match(sentenceRegex)
  if (match) {
    return match[0].trim()
  }

  return largeText
}

export const toolsSlice = createSlice({
  name: "tools",
  initialState: {
    selectedText: "",
  },
  reducers: {
    setSelectedText: (state, action) => {
      state.selectedText = returnMyText(action.payload)
    },
  },
})

export const { setSelectedText } = toolsSlice.actions

export default toolsSlice.reducer
