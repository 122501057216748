import { DictionaryType, Meaning, Phonetic } from "../type/Dictionary"
import { getAPIPath, returnIdTokenResult } from "../utils/utiltyHelper"

const searchForDictionaryAPI = (
  text: string
): Promise<
  | {
      success: true
      data: DictionaryType[]
    }
  | {
      success: false
    }
> => {
  return new Promise((resolve) => {
    let url = `https://api.dictionaryapi.dev/api/v2/entries/en/${text}`
    fetch(url)
      .then((res) => res.json())
      .then((result: DictionaryType[]) => {
        if (Array.isArray(result)) {
          return resolve({ success: true, data: result as DictionaryType[] })
        }
        return resolve({ success: false })
      })
      .catch((err) => {
        console.error("Dictionary API error:", err)
        return resolve({ success: false })
      })
  })
}

const searchForChineseTranslation = (
  text: string
): Promise<
  | {
      success: true
      data: string
    }
  | {
      success: false
    }
> => {
  return new Promise(async (resolve, reject) => {
    returnIdTokenResult().then(async (res: any) => {
      await fetch(getAPIPath("/dictionary/meaning"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          idToken: res.token,
        },
        body: JSON.stringify({
          text: text,
        }),
      })
        .then((res) => res.json())
        .then((response) => {
          return resolve(response)
        })
        .catch((err) => {
          console.log(err)
          return resolve({
            success: false,
          })
        })
    })
  })
}

const getTranslation = (
  text: string
): Promise<{
  success: true
  data: {
    meanings: Meaning[]
    phonetics: Phonetic[]
    word: string
    wordCHI: string
  }
}> => {
  return new Promise(async (resolve) => {
    const meaningState = await searchForDictionaryAPI(text)
    const chineseTranState = await searchForChineseTranslation(text)

    let draftResponse = {
      meanings: [],
      phonetics: [],
      word: "",
      wordCHI: "",
    }

    if (meaningState.success) {
      draftResponse = {
        ...draftResponse,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        meanings: meaningState.data[0].meanings,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        phonetics: meaningState.data[0].phonetics,
        word: text,
      }
    }

    if (chineseTranState.success) {
      draftResponse = {
        ...draftResponse,
        wordCHI: chineseTranState.data,
      }
    }

    return resolve({
      success: true,
      data: draftResponse,
    })
  })
}

export { getTranslation }
