import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { showNotification } from "../../redux/headerSlice"
import { Submission } from "../../type/Submission"
import { submitUserAnswer } from "../../helperFunction/quizDBHelper"
import { Quiz } from "../../type/Quiz"
import classNames from "classnames"
import { Trans } from "react-i18next"
import { t } from "i18next"

interface PropsType {
  closeModal: () => void
}

const ConfirmQuizSubmissionBody: React.FC<PropsType> = ({ closeModal }) => {
  const dispatch = useDispatch()

  const quiz: Quiz = useSelector((state: any) => {
    return state.quiz.quiz
  })

  const userSubmission: Submission = useSelector((state: any) => {
    return state.quiz.userSubmission
  })

  const [isLoading, setIsLoading] = useState<boolean>(false)

  const submit = () => {
    setIsLoading(true)
    submitUserAnswer(quiz.id as string, userSubmission).then((result) => {
      setIsLoading(false)
      if (result.success) {
        setTimeout(() => {
          window.location.reload()
          dispatch(
            showNotification({
              message: t("ConfirmQuizSubmission.your-answer-submitted"),
              status: 1,
            })
          )
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          dispatch(closeModal())
        }, 2500)
      }
    })
  }

  return (
    <>
      <div>
        <Trans>ConfirmQuizSubmission.des</Trans>
      </div>

      <div className="modal-action">
        <button className="btn btn-ghost" onClick={() => closeModal()}>
          <Trans>ConfirmQuizSubmission.cancel</Trans>
        </button>
        <button
          className={classNames("btn text-white btn-primary px-6", {
            "loading loading-spinner": isLoading,
          })}
          disabled={isLoading}
          onClick={() => submit()}>
          {isLoading ? (
            <Trans>ConfirmQuizSubmission.loading</Trans>
          ) : (
            <Trans>ConfirmQuizSubmission.submit</Trans>
          )}
        </button>
      </div>
    </>
  )
}

export default ConfirmQuizSubmissionBody
