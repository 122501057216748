import React from "react"
import TitleCard from "./Cards/TitleCard"
import { useDispatch, useSelector } from "react-redux"
import { VOCAB_LIST } from "../type/Article"
import { onSelectWordLevel } from "../redux/newsSlice"
import { Trans } from "react-i18next"
import { t } from "i18next"

interface PropsType {
  height?: string
  useCollapse: boolean
}

const WordLevelSelection: React.FC<PropsType> = ({ height, useCollapse }) => {
  const dispatch = useDispatch()

  const { selectedWordLevel } = useSelector((state: any) => state.news)

  return (
    <TitleCard
      height={height}
      isHiddenScrollBar={true}
      title={t("FullNewsCard.word-level") as string}
      useCollapse={useCollapse}
      topMargin="mt-2">
      <div className="grid grid-cols-2 gap-4">
        <div className="form-control">
          <label className="label gap-2 cursor-pointer justify-start">
            <input
              type="checkbox"
              checked={selectedWordLevel.includes(VOCAB_LIST.EASY)}
              className="checkbox checkbox-primary"
              onChange={() => dispatch(onSelectWordLevel(VOCAB_LIST.EASY))}
            />
            <span className="label-text">
              <Trans>FullNewsCard.IELTS-easy</Trans>
            </span>
          </label>
        </div>

        <div className="form-control">
          <label className="label gap-2 cursor-pointer justify-start">
            <input
              type="checkbox"
              checked={selectedWordLevel.includes(VOCAB_LIST.MEDIUM)}
              className="checkbox checkbox-primary"
              onChange={() => dispatch(onSelectWordLevel(VOCAB_LIST.MEDIUM))}
            />
            <span className="label-text">
              <Trans>FullNewsCard.IELTS-medium</Trans>
            </span>
          </label>
        </div>

        <div className="form-control">
          <label className="label gap-2 cursor-pointer justify-start">
            <input
              type="checkbox"
              checked={selectedWordLevel.includes(VOCAB_LIST.HARD)}
              className="checkbox checkbox-primary justify-start"
              onChange={() => dispatch(onSelectWordLevel(VOCAB_LIST.HARD))}
            />
            <span className="label-text">
              <Trans>FullNewsCard.IELTS-hard</Trans>
            </span>
          </label>
        </div>

        <div className="form-control">
          <label className="label gap-2 cursor-pointer justify-start">
            <input
              type="checkbox"
              checked={selectedWordLevel.includes(VOCAB_LIST.PHRASAL_VERB)}
              className="checkbox checkbox-primary justify-start"
              onChange={() =>
                dispatch(onSelectWordLevel(VOCAB_LIST.PHRASAL_VERB))
              }
            />
            <span className="label-text">
              <Trans>FullNewsCard.phrasal-verb</Trans>
            </span>
          </label>
        </div>
      </div>
    </TitleCard>
  )
}
export default WordLevelSelection
