import { PanInfo, motion } from "framer-motion"
import React, { useState } from "react"
import { setSelectedText } from "../../redux/toolsSlice"
import { useDispatch } from "react-redux"
import Dictionary from "../Dictionary/Dictionary"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faRepeat, faUser } from "@fortawesome/free-solid-svg-icons"
import { WordObject } from "../../type/User"
import { useParams } from "react-router-dom"
import classNames from "classnames"

export type HistoryType = WordObject & { swipe: SwipeType }

interface Interface {
  word: WordObject
  active: boolean
  removeCard: (oldCard: WordObject, swipe: SwipeType) => void
  setButtonVisible: (object: {
    save: boolean
    remove: boolean
    skip: boolean
  }) => void
}

export enum SwipeType {
  DELETE = "delete",
  SKIP = "skip",
  LIKE = "like",
}

const Card: React.FC<Interface> = ({
  word,
  removeCard,
  active,
  setButtonVisible,
}) => {
  const dispatch = useDispatch()
  const params = useParams()

  const [leaveX, setLeaveX] = useState<number>(0)
  const [leaveY, setLeaveY] = useState<number>(0)
  const [isFlipped, setIsFlipped] = useState<boolean>(false)

  const onDragEnd = (_e: any, info: PanInfo) => {
    if (params.folderId !== "archive") {
      if (info.offset.y < -100) {
        setLeaveY(-2000)
        removeCard(word, SwipeType.DELETE)
      }
      if (info.offset.x > 100) {
        setLeaveX(1000)
        removeCard(word, SwipeType.LIKE)
      }
      if (info.offset.x < -100) {
        setLeaveX(-1000)
        removeCard(word, SwipeType.SKIP)
      }

      setButtonVisible({
        save: false,
        remove: false,
        skip: false,
      })
    } else {
      // if archive, every move will auto assigned to delete
      setLeaveY(-2000)
      removeCard(word, SwipeType.DELETE)
    }
  }

  const onDragCapture = (_e: any, info: PanInfo) => {
    if (info.offset.y < -50) {
      setButtonVisible({
        save: false,
        remove: true,
        skip: false,
      })
    }
    if (info.offset.x > 50) {
      setButtonVisible({
        save: true,
        remove: false,
        skip: false,
      })
    }
    if (info.offset.x < -50) {
      setButtonVisible({
        save: false,
        remove: false,
        skip: true,
      })
    }
  }

  const onFlipCard = (word: string) => {
    setIsFlipped(true)
    dispatch(setSelectedText(word))
  }

  const cancelFlip = () => {
    setIsFlipped(false)
    dispatch(setSelectedText(""))
  }

  const css =
    "absolute top-20 h-[430px] w-[300px] lg:h-[500px] lg:w-[390px] bg-white  rounded-2xl flex flex-col justify-center items-center cursor-grab"
  return (
    <>
      {active ? (
        <motion.div
          drag={true}
          dragConstraints={{ left: 0, right: 0, top: 0, bottom: 0 }}
          onDragEnd={onDragEnd}
          onDrag={onDragCapture}
          initial={{
            scale: 1,
          }}
          animate={{
            rotateY: isFlipped ? 180 : 0,
          }}
          exit={{
            x: leaveX,
            y: leaveY,
            opacity: 0,
            scale: 0.5,
            transition: { duration: 0.2 },
          }}
          className={classNames(css, "shadow-xl")}
          data-testid="active-card">
          {isFlipped ? (
            <div
              className="relative w-full h-full overflow-hidden rounded-2xl"
              style={{ transform: "rotateY(180deg)" }}>
              <Dictionary />

              <div className="flex justify-center absolute -bottom-2 left-0 w-full mb-4">
                <button
                  className="btn bg-white border-gray-100 btn-circle btn-ghost"
                  onClick={() => cancelFlip()}>
                  <FontAwesomeIcon
                    icon={faRepeat}
                    className="text-gray-700 my-auto text-lg"
                  />
                </button>
              </div>
            </div>
          ) : (
            <div>
              <div className="flex justify-center absolute -bottom-2 left-0 w-full mb-4">
                <button
                  className=" mx-auto bg-white border border-gray-100 btn btn-circle btn-ghost"
                  onClick={() => onFlipCard(word.name)}>
                  <FontAwesomeIcon
                    icon={faRepeat}
                    className="text-gray-700 my-auto text-lg"
                  />
                </button>
              </div>
              <div className="text-4xl font-bold text-center">{word.name}</div>
              <div className="text-xl text-gray-400 text-center mt-2">
                {Array.isArray(word.partOfSpeech) &&
                  word.partOfSpeech.map((e) => e).join(" | ")}
              </div>
            </div>
          )}
        </motion.div>
      ) : (
        <div
          className={`${css} ${
            word.name.length % 2 === 0 ? "rotate-3" : "-rotate-3"
          }`}>
          {word.name}
        </div>
      )}
    </>
  )
}

export default Card
