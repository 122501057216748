import React, { useEffect, useImperativeHandle, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { MC, QuestionType } from "../../type/Question"
import classNames from "classnames"
import { Answer, defaultAnswer, Submission } from "../../type/Submission"
import { ALERT_TYPE, BUTTON_TYPE } from "../../enum/ALERT_SYSYEM"
import { faCircleXmark } from "@fortawesome/free-solid-svg-icons"
import CustomAlert from "../../components/Alert"
import { SubmissionUpdate, updateUserAnswer } from "../../redux/quizSlice"
import { getUserLang, isEmpty } from "../../utils/utiltyHelper"

interface PropsType {
  data: MC
}

const MCQuestionCard: React.FC<PropsType> = ({ data }, ref: any) => {
  const dispatch = useDispatch()

  const userSubmission: Submission = useSelector((state: any) => {
    return state.quiz.userSubmission
  })

  const userAnsweredResult: Answer = useSelector((state: any) => {
    if (!isEmpty(state.quiz.userSubmission) && state.quiz.userSubmission) {
      const ans: Answer = state.quiz.userSubmission.answers.find(
        (e: Answer) => e.questionID === data.id
      )
      if (ans) {
        return ans
      }
      return {
        ...defaultAnswer,
      }
    }
    return {
      ...defaultAnswer,
    }
  })

  const isAnswered = userSubmission.isScored

  const onSelectOption = (val: string) => {
    let newAns: string[] = []

    if (data.isMultiple) {
      let uAnswer: string[] = userAnsweredResult?.answer as string[]
      if (uAnswer.includes(val)) {
        newAns = uAnswer.filter((e) => e !== val)
      } else {
        newAns = [...uAnswer, val]
      }
    } else {
      newAns = userAnsweredResult?.answer.includes(val) ? [] : [val]
    }

    const updatedData: SubmissionUpdate = {
      questionID: data.id as string,
      answer: newAns,
    }

    dispatch(updateUserAnswer(updatedData))
  }

  useImperativeHandle(ref, () => ({
    returnData() {
      return userAnsweredResult?.answer
    },
  }))

  if (isAnswered) {
    return (
      <div>
        <p className="text-lg">{data.question}</p>
        {data.isMultiple && <span className="badge">Multiple</span>}
        {/*  Options*/}
        <div className="grid gap-2 my-2">
          {data.options.map((option, index) => {
            const userSelected = userAnsweredResult.answer.includes(
              option.value
            )
            const isCorrectAns = data.answer.includes(option.value)
            const isWrong = userSelected && !isCorrectAns
            const notSelected = !userSelected && !isCorrectAns
            return (
              <div
                className={classNames(
                  "cursor-pointer p-4 w-full text-lg rounded",
                  {
                    "bg-green-200": isCorrectAns,
                    "bg-red-200": isWrong,
                    "bg-gray-50": notSelected && !userSelected,
                  }
                )}
                key={option.value}>
                {option.label}
              </div>
            )
          })}
        </div>

        {userSubmission.isScored ? (
          <div>
            <div className="text-2xl bg-green-100 text-green-700 rounded-2xl text-center w-24 p-4">
              {userAnsweredResult.score} / {data.points}
            </div>
            <p className="bg-gray-50 text-gray-500 text-sm rounded-2xl my-2 p-4">
              {getUserLang() === "hk" ? data.reasonCHI : data.reason}
            </p>
          </div>
        ) : (
          <CustomAlert
            title={"QuestionCardManager.system-processing"}
            alertType={ALERT_TYPE.ERROR}
            buttonType={{
              type: BUTTON_TYPE.NONE,
            }}
            icon={faCircleXmark}
          />
        )}
      </div>
    )
  }
  return (
    <div>
      <p className="text-lg">{data.question}</p>

      {data.isMultiple && <span className="badge">Multiple</span>}

      {/*  Options*/}
      <div className="grid gap-2 my-2">
        {data.options.map((option, index) => {
          return (
            <div
              className={classNames(
                "cursor-pointer p-4 w-full text-lg rounded",
                {
                  "bg-blue-200": userAnsweredResult.answer.includes(
                    option.value
                  ),
                  "bg-gray-50": !userAnsweredResult.answer.includes(
                    option.value
                  ),
                }
              )}
              key={option.value}
              onClick={() => onSelectOption(option.value)}>
              {option.label}
            </div>
          )
        })}
      </div>
    </div>
  )
}
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export default React.forwardRef(MCQuestionCard)
