import {
  collection,
  doc,
  getCountFromServer,
  getDoc,
  getDocs,
  getFirestore,
  limit,
  query,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore"
import firebaseApp from "../config/firebase"
import {
  ArticleReadHistory,
  defaultArticleReadHistory,
  LocalUserCollection,
  User,
  UserCollectionWord,
  WordObject,
} from "../type/User"
import { Quiz } from "../type/Quiz"
import { v4 as uuidv4 } from "uuid"
import { Article, UserArticle } from "../type/Article"
import { objectFieldsToDateType, timeConverter } from "../utils/utiltyHelper"
import { groupWordsByDocID } from "./bookmarkHelper"
import { DateObj } from "../type/SystemType"
import { ROLE } from "../enum/APP_TYPE"

const db = getFirestore(firebaseApp)
const getUser = (
  uid: string
): Promise<
  | {
      success: true
      data: User
    }
  | {
      success: false
    }
> => {
  return new Promise(async (resolve) => {
    await getDoc(doc(db, "User", uid))
      .then((doc: any) => {
        if (doc.exists()) {
          return resolve({
            success: true,
            data: {
              id: doc.id,
              ...doc.data(),
            } as User,
          })
        }
        return resolve({
          success: false,
        })
      })
      .catch((err: any) => {
        console.log(err)
        return resolve({
          success: false,
        })
      })
  })
}

const updateUser = (
  uid: string,
  data: User
): Promise<
  | {
      success: true
      data: User
    }
  | {
      success: false
    }
> => {
  let newUser: User = objectFieldsToDateType({ ...data })
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  delete newUser.id

  return new Promise(async (resolve) => {
    await updateDoc(doc(db, "User", uid), {
      ...newUser,
    })
      .then((doc: any) => {
        if (doc.exists()) {
          return resolve({
            success: true,
            data: {
              id: doc.id,
              ...doc.data(),
            } as User,
          })
        }
        return resolve({
          success: false,
        })
      })
      .catch((err: any) => {
        return resolve({
          success: false,
        })
      })
  })
}

const getUserCollected = (
  uid: string
): Promise<
  | {
      success: true
      data: WordObject[]
    }
  | {
      success: false
    }
> => {
  return new Promise(async (resolve) => {
    let dbRef: any = collection(db, "User", uid, "Collection")

    // dbRef = query(dbRef, where("createUserID", "==", uid))

    await getDocs(query(dbRef, limit(20)))
      .then((docs: any) => {
        if (!docs.empty) {
          let allWordObjects: WordObject[] = []
          docs.forEach((doc: any) => {
            const allWords: WordObject[] = doc
              .data()
              .collected.map((e: UserCollectionWord) => ({
                ...e,
                id: uuidv4(),
                docID: doc.id,
              }))

            allWordObjects = allWordObjects.concat(allWords)
          })

          return resolve({
            success: true,
            data: objectFieldsToDateType(allWordObjects),
          })
        }
        return resolve({
          success: true,
          data: [],
        })
      })
      .catch((err: any) => {
        console.log(err)
        return resolve({
          success: false,
        })
      })
  })
}

const updateUserCollected = (
  uid: string,
  data: WordObject[]
): Promise<
  | {
      success: true
      data: LocalUserCollection[]
    }
  | {
      success: false
    }
> => {
  return new Promise(async (resolve) => {
    //do doc labelling here
    let allPromise: Promise<any>[] = []
    const localUserCollection: LocalUserCollection[] = groupWordsByDocID(data)

    objectFieldsToDateType(localUserCollection).map(async (el: any) => {
      allPromise.push(
        new Promise(async (resolve1) => {
          await setDoc(doc(db, "User", uid, "Collection", el.docID), {
            collected: el.record,
            lastUpdate: new Date(),
          })
            .then((doc: any) => {
              return resolve1({
                success: true,
              })
            })
            .catch((err: any) => {
              console.log(err)
              return resolve1({
                success: false,
              })
            })
        })
      )
    })

    Promise.all(allPromise).then((result) => {
      return resolve({
        success: true,
        data: localUserCollection,
      })
    })
  })
}

export interface getAllUserCreatedQuizzesCondition {
  startAfter?: "" | DateObj | Date
  endAt?: "" | DateObj | Date
  isPublish?: "" | boolean
}

const getUserCreatedQuizzes = (
  orgID: string,
  uid: string,
  condition: getAllUserCreatedQuizzesCondition
): Promise<
  | {
      success: true
      data: Quiz[]
      hasMore: boolean
    }
  | {
      success: false
    }
> => {
  return new Promise(async (resolve) => {
    let dbRef: any = collection(db, "Quiz")
    dbRef = query(dbRef, where("orgID", "==", orgID))
    dbRef = query(dbRef, where("createUserID", "==", uid))

    if (condition.startAfter) {
      dbRef = query(dbRef, where("createDate", ">=", condition.startAfter))
    }

    if (condition.endAt) {
      dbRef = query(dbRef, where("createDate", "<", condition.endAt))
    }

    await getDocs(query(dbRef, limit(40)))
      .then((docs: any) => {
        if (!docs.empty) {
          let allQuizzes: Quiz[] = []
          docs.forEach((doc: any) => {
            allQuizzes.push({
              id: doc.id,
              ...doc.data(),
            } as Quiz)
          })

          return resolve({
            success: true,
            data: allQuizzes,
            hasMore: allQuizzes.length === 10,
          })
        }
        return resolve({
          success: true,
          data: [],
          hasMore: false,
        })
      })
      .catch((err: any) => {
        console.log(err)
        return resolve({
          success: false,
        })
      })
  })
}

export interface getSaveArticleReadHistoryCondition {
  startAfter?: "" | DateObj | Date
  endAt?: "" | DateObj | Date
}

const getUserReadArticles = (
  uid: string,
  condition: getSaveArticleReadHistoryCondition
): Promise<
  | {
      success: true
      data: ArticleReadHistory[]
      hasMore: boolean
    }
  | {
      success: false
    }
> => {
  return new Promise(async (resolve) => {
    let dbRef: any = collection(db, "User", uid, "Article")

    if (condition.startAfter) {
      dbRef = query(dbRef, where("createDate", ">=", condition.startAfter))
    }

    if (condition.endAt) {
      dbRef = query(dbRef, where("createDate", "<", condition.endAt))
    }

    await getDocs(query(dbRef, limit(10)))
      .then((docs: any) => {
        if (!docs.empty) {
          let allUserArticles: ArticleReadHistory[] = []
          docs.forEach((doc: any) => {
            allUserArticles.push({
              id: doc.id,
              ...doc.data(),
            } as ArticleReadHistory)
          })

          return resolve({
            success: true,
            data: allUserArticles,
            hasMore: allUserArticles.length === 10,
          })
        }
        return resolve({
          success: true,
          data: [],
          hasMore: false,
        })
      })
      .catch((err: any) => {
        console.log(err)
        return resolve({
          success: false,
        })
      })
  })
}

export interface countArticleReadHistoryCondition {
  startAfter?: "" | DateObj | Date
  endAt?: "" | DateObj | Date
}

const countArticleReadHistory = (
  uid: string,
  condition: countArticleReadHistoryCondition
): Promise<
  | {
      success: true
      data: number
    }
  | {
      success: false
    }
> => {
  return new Promise(async (resolve) => {
    let dbRef: any = collection(db, "User", uid, "Article")

    if (condition.startAfter) {
      dbRef = query(dbRef, where("createDate", ">=", condition.startAfter))
    }

    if (condition.endAt) {
      dbRef = query(dbRef, where("createDate", "<", condition.endAt))
    }

    const snapshot = await getCountFromServer(dbRef)

    return resolve({
      success: true,
      data: snapshot.data().count,
    })
  })
}

const saveArticleReadHistory = (
  article: Article,
  profile: User
): Promise<{
  success: boolean
}> => {
  return new Promise(async (resolve) => {
    if (profile.role === ROLE.ORG_USER) {
      const record = {
        ...defaultArticleReadHistory,
        articleTitle: article.title,
      }

      await setDoc(
        doc(db, "User", profile.id as string, "Article", article.id as string),
        record
      )
        .then((doc) => {
          return resolve({
            success: true,
          })
        })
        .catch((err) => {
          console.log(err)
          return resolve({
            success: false,
          })
        })
    } else {
      return resolve({
        success: false,
      })
    }
  })
}

/**
 *
 * @param orgGroups
 */
const getOrgGroupUserCount = (
  orgID: string,
  orgGroups: string[]
): Promise<
  | {
      success: true
      data: number
    }
  | {
      success: false
    }
> => {
  return new Promise(async (resolve) => {
    let dbRef: any = collection(db, "User")
    dbRef = query(dbRef, where("orgID", "==", orgID))
    dbRef = query(dbRef, where("role", "==", "org-user"))
    dbRef = query(dbRef, where("orgGroups", "array-contains-any", orgGroups))

    dbRef = query(dbRef)
    const snapshot = await getCountFromServer(dbRef)

    return resolve({
      success: true,
      data: snapshot.data().count,
    })
  })
}

const updateUserLoginRecord = (
  profile: User
): Promise<{
  success: boolean
}> => {
  return new Promise(async (resolve) => {
    const isSameDay =
      timeConverter(profile.lastLogin).getDay() === new Date().getDay()

    if (profile.role === ROLE.ORG_USER && !isSameDay) {
      await updateDoc(doc(db, "User", profile.id), {
        lastLogin: new Date(),
      })
        .then((doc) => {
          return resolve({
            success: true,
          })
        })
        .catch((err) => {
          console.log(err)
          return resolve({
            success: false,
          })
        })
    } else {
      return resolve({
        success: false,
      })
    }
  })
}

const getUserTodayOnlineCount = (
  orgID: string
): Promise<
  | {
      success: true
      data: number
    }
  | {
      success: false
    }
> => {
  return new Promise(async (resolve) => {
    const startFromToday = new Date()
    startFromToday.setHours(0, 0, 0, 0)

    let dbRef: any = collection(db, "User")
    dbRef = query(dbRef, where("orgID", "==", orgID))
    dbRef = query(dbRef, where("lastLogin", ">", startFromToday))

    dbRef = query(dbRef)
    const snapshot = await getCountFromServer(dbRef)

    return resolve({
      success: true,
      data: snapshot.data().count,
    })
  })
}

export {
  getUser,
  getUserCollected,
  updateUserCollected,
  getUserCreatedQuizzes,
  getUserReadArticles,
  updateUser,
  countArticleReadHistory,
  saveArticleReadHistory,
  getOrgGroupUserCount,
  updateUserLoginRecord,
  getUserTodayOnlineCount,
}
