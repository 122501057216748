import React, {
  lazy,
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from "react"

import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom"
import * as serviceWorkerRegistration from "../serviceWorkerRegistration.js"
import initializeApp from "../app/init"

// import store from "./app/store"
import { useDispatch, useSelector } from "react-redux"
import { getAuth, onAuthStateChanged } from "firebase/auth"
import toast, { Toaster } from "react-hot-toast"

import PageHOC from "../components/PageHOC"
import { setScreenHeight, setScreenWidth } from "../redux/systemSlice"
import { isAuth } from "../utils/utiltyHelper"
import { getUserProfileThunk, getUserCollectionThunk } from "../redux/userSlice"
import { setLogin } from "../redux/firebaseSlice"
import { ROLE } from "../enum/APP_TYPE"

import { getOrgProfileByID } from "../redux/orgManagementSlice"
import { Trans } from "react-i18next"

const fbAuth = getAuth()

// Importing pages
const Layout = lazy(() => import("../containers/Layout"))
const Login = lazy(() => import("../pages/Login"))
const ForgotPassword = lazy(() => import("../pages/ForgotPassword"))
const Setting = lazy(() => import("../pages/Setting"))

// Initializing different libraries
initializeApp()

function RouterManager() {
  const dispatch = useDispatch()

  const auth = useSelector((state: any) => {
    return state.firebase.auth
  })

  const [waitingWorker, setWaitingWorker] = useState<ServiceWorker | null>(null)
  const [showReload, setShowReload] = useState<boolean>(false)

  useLayoutEffect(() => {
    function updateSize() {
      dispatch(setScreenWidth(window.innerWidth))
      dispatch(setScreenHeight(window.innerHeight))
    }

    window.addEventListener("resize", updateSize)
    updateSize()
    return () => window.removeEventListener("resize", updateSize)
  }, [])

  useEffect(() => {
    onAuthStateChanged(fbAuth, (user) => {
      if (user) {
        user.getIdTokenResult().then((idTokenResult) => {
          dispatch(
            setLogin({
              email: user.email,
              uid: user.uid,
              photoURL: user.photoURL,
              displayName: user.displayName,
              token: idTokenResult.token,
              role: idTokenResult.claims.role as ROLE,
              expirationTime: idTokenResult.expirationTime,
              custID: idTokenResult.claims.custID
                ? idTokenResult.claims.custID
                : null,
              orgID: idTokenResult.claims.orgID
                ? idTokenResult.claims.orgID
                : null,
            })
          )
        })
      } else {
        // logout()
      }
    })
  }, [fbAuth])

  useEffect(() => {
    console.log("getting profile")
    if (isAuth(auth)) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      dispatch(getUserProfileThunk(auth.uid))
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      dispatch(getOrgProfileByID(auth.orgID as string))

      if (auth.role === ROLE.ORG_USER) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        dispatch(getUserCollectionThunk(auth.uid))
      }
    }
  }, [auth])

  const onSWUpdate = useCallback((registration: ServiceWorkerRegistration) => {
    setShowReload(true)
    setWaitingWorker(registration.waiting)
  }, [])

  const reloadPage = useCallback(() => {
    waitingWorker?.postMessage({ type: "SKIP_WAITING" })
    setShowReload(false)
    window.location.reload()
  }, [waitingWorker])

  useEffect(() => {
    serviceWorkerRegistration.register({
      onUpdate: onSWUpdate,
    })
  }, [onSWUpdate])

  // decides when to show the toast
  useEffect(() => {
    if (showReload && waitingWorker) {
      toast(
        <div className="flex flex-row items-center">
          <p>
            <Trans>ServiceWorkerToast.title</Trans>
          </p>
          <button
            className="w-fit bg-gray-700 text-white rounded-lg mx-4 p-2"
            onClick={() => reloadPage()}>
            <Trans>ServiceWorkerToast.button-text</Trans>
          </button>
        </div>
      )
    }
  }, [waitingWorker, showReload, reloadPage])

  return (
    <>
      <Router>
        <Routes>
          <Route
            path="/login"
            element={
              <PageHOC>
                <Login />
              </PageHOC>
            }
          />
          <Route
            path="/forgot-password"
            element={
              <PageHOC>
                <ForgotPassword />
              </PageHOC>
            }
          />

          <Route
            path="/setting"
            element={
              <PageHOC>
                <Setting />
              </PageHOC>
            }
          />

          {/* Place new routes over this */}
          <Route path="/app/*" element={<Layout />} />
          <Route path="/management/*" element={<Layout />} />

          <Route
            path="*"
            element={
              <Navigate to={isAuth(auth) ? "/app/landing" : "/login"} replace />
            }
          />
        </Routes>
      </Router>
    </>
  )
}

export default RouterManager
