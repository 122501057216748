import React from "react"
import TitleCard from "./Cards/TitleCard"
import { useDispatch, useSelector } from "react-redux"
import { VOCAB_LIST } from "../type/Article"
import { onSelectWordLevel } from "../redux/newsSlice"
import { setSelectedText } from "../redux/toolsSlice"
import { t } from "i18next"

interface PropsType {
  height?: string
  useCollapse: boolean
}

const DifficultWordsList: React.FC<PropsType> = ({ height, useCollapse }) => {
  const dispatch = useDispatch()

  const { selectedArticle, selectedArticleLevel } = useSelector(
    (state: any) => state.news
  )

  return (
    <TitleCard
      height={height}
      isHiddenScrollBar={true}
      title={t("FullNewsCard.academic-words") as string}
      useCollapse={useCollapse}
      topMargin="mt-2">
      <div>
        {selectedArticleLevel &&
          Array.isArray(selectedArticle[selectedArticleLevel].difficultWords) &&
          selectedArticle[selectedArticleLevel].difficultWords.map(
            (e: string, index: number) => {
              return (
                <span
                  key={index}
                  className="cursor-pointer badge m-1 badge-lg badge-primary"
                  onClick={() => {
                    dispatch(setSelectedText(e))
                  }}>
                  {e}
                </span>
              )
            }
          )}
      </div>
    </TitleCard>
  )
}
export default DifficultWordsList
