import {
  deleteDoc,
  collection,
  doc,
  getCountFromServer,
  getDoc,
  getDocs,
  getFirestore,
  limit,
  orderBy,
  query,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore"
import firebaseApp from "../config/firebase"
import { Quiz } from "../type/Quiz"
import { timeConverter, objectFieldsToDateType } from "../utils/utiltyHelper"
import {
  Submission,
  defaultAnswerCommon,
  UserWithSubmission,
  QuizWithSubmission,
} from "../type/Submission"
import { OrgStudentUser } from "../type/User"
import { getUser } from "./userDBHelper"
import { DateObj } from "../type/SystemType"
import { ListQuizViewItem } from "../pages/protected/ListQuiz"

const db = getFirestore(firebaseApp)

const getQuiz = (
  id: string
): Promise<{ success: true; data: Quiz } | { success: false }> => {
  return new Promise(async (resolve) => {
    await getDoc(doc(db, "Quiz", id))
      .then((doc) => {
        if (doc.exists()) {
          return resolve({
            success: true,
            data: {
              id: doc.id,
              ...doc.data(),
            } as Quiz,
          })
        }
        return resolve({
          success: false,
        })
      })
      .catch((err) => {
        console.log(err)
        return resolve({
          success: false,
        })
      })
  })
}

/**
 * addQuiz
 * @param q
 */
const addQuiz = (
  q: Quiz
): Promise<{
  success: boolean
}> => {
  let newQuiz: Quiz = { ...q }
  delete newQuiz.id

  return new Promise(async (resolve) => {
    await setDoc(doc(db, "Quiz", q.id as string), newQuiz)
      .then((res) => {
        return resolve({
          success: true,
        })
      })
      .catch((err) => console.log(err))
  })
}

/**
 * update quiz
 * @param q
 */
const updateQuiz = (
  q: Quiz
): Promise<{
  success: boolean
}> => {
  let updatedQuiz: Quiz = { ...q }
  delete updatedQuiz.id

  return new Promise(async (resolve) => {
    await updateDoc(doc(db, "Quiz", q.id as string), {
      ...updatedQuiz,
      createDate: timeConverter(updatedQuiz.createDate),
      startDate: timeConverter(updatedQuiz.startDate),
      endDate: timeConverter(updatedQuiz.endDate),
    })
      .then((res) => {
        return resolve({
          success: true,
        })
      })
      .catch((err) => console.log(err))
  })
}

/**
 * update quiz
 * @param q
 */
const removeQuiz = (
  qid: string
): Promise<{
  success: boolean
}> => {
  return new Promise(async (resolve) => {
    await deleteDoc(doc(db, "Quiz", qid as string))
      .then((res) => {
        return resolve({
          success: true,
        })
      })
      .catch((err) => console.log(err))
  })
}

/**
 *
 * @param orgID
 * @param condition
 */
export interface getUserAssignedQuizCondition {
  startAfter?: "" | DateObj | Date
  endAt?: "" | DateObj | Date
  type?: "" | ListQuizViewItem
  uid?: "" | string
}

const getUserAssignedQuiz = (
  orgID: string,
  orgGroups: string[],
  condition: getUserAssignedQuizCondition
): Promise<
  | {
      success: true
      data: Quiz[]
      hasMore: boolean
    }
  | {
      success: false
    }
> => {
  return new Promise(async (resolve) => {
    let dbRef: any = collection(db, "Quiz")
    console.log(orgID, condition, orgGroups)
    if (condition.startAfter) {
      dbRef = query(dbRef, where("createDate", ">=", condition.startAfter))
    }

    if (condition.endAt) {
      dbRef = query(dbRef, where("createDate", "<", condition.endAt))
    }

    if (condition.type === ListQuizViewItem.USER && condition.uid !== "") {
      dbRef = query(dbRef, where("createUserID", "==", condition.uid))
    } else if (orgID && Array.isArray(orgGroups) && orgGroups.length > 0) {
      dbRef = query(dbRef, where("orgID", "==", orgID))
      dbRef = query(dbRef, where("orgGroups", "array-contains-any", orgGroups))
    } else {
      return resolve({
        success: false,
      })
    }

    await getDocs(query(dbRef, limit(10)))
      .then((docs: any) => {
        if (!docs.empty) {
          let allQuiz: Quiz[] = []
          docs.forEach((doc: any) => {
            allQuiz.push({
              id: doc.id,
              ...doc.data(),
            } as Quiz)
          })

          return resolve({
            success: true,
            data: allQuiz,
            hasMore: allQuiz.length === 10,
          })
        }
        return resolve({
          success: true,
          data: [],
          hasMore: false,
        })
      })
      .catch((err: any) => {
        console.log(err)
        return resolve({
          success: false,
        })
      })
  })
}

export interface countUserVocabQuizHistoryCondition {
  startAfter?: "" | DateObj | Date
  endAt?: "" | DateObj | Date
}

const countUserVocabQuizHistory = (
  uid: string,
  condition: countUserVocabQuizHistoryCondition
): Promise<
  | {
      success: true
      data: number
    }
  | {
      success: false
    }
> => {
  return new Promise(async (resolve) => {
    let dbRef: any = collection(db, "Quiz")

    if (condition.startAfter) {
      dbRef = query(dbRef, where("createDate", ">=", condition.startAfter))
    }

    if (condition.endAt) {
      dbRef = query(dbRef, where("createDate", "<", condition.endAt))
    }

    dbRef = query(dbRef, where("createUserID", "==", uid))
    const snapshot = await getCountFromServer(dbRef)

    return resolve({
      success: true,
      data: snapshot.data().count,
    })
  })
}

/**
 *
 * @param orgID
 * @param orgGroups
 */
const getUserAssignedQuizCount = (
  orgID: string,
  orgGroups: string[]
): Promise<
  | {
      success: true
      data: number
    }
  | {
      success: false
    }
> => {
  return new Promise(async (resolve) => {
    let dbRef: any = collection(db, "Quiz")

    dbRef = query(dbRef, where("orgID", "==", orgID))
    dbRef = query(dbRef, where("orgGroups", "array-contains-any", orgGroups))
    await getCountFromServer(dbRef)
      .then((snapshot) => {
        return resolve({
          success: true,
          data: snapshot.data().count,
        })
      })
      .catch((err) => console.log(err))
  })
}

const getUsersQuizSubmission = (
  id: string,
  uid: string
): Promise<{ success: true; data: Submission } | { success: false }> => {
  return new Promise(async (resolve) => {
    await getDoc(doc(db, "Quiz", id, "Submission", uid))
      .then((doc) => {
        if (doc.exists()) {
          return resolve({
            success: true,
            data: {
              id: doc.id,
              ...doc.data(),
            } as Submission,
          })
        }
        return resolve({
          success: false,
        })
      })
      .catch((err) => {
        console.log(err)
        return resolve({
          success: false,
        })
      })
  })
}

const isUserDoneQuiz = (
  id: string,
  uid: string
): Promise<{ success: boolean }> => {
  return new Promise(async (resolve) => {
    let dbRef: any = collection(db, "Quiz", id, "Submission")
    dbRef = query(dbRef, where("isSubmitted", "==", true))
    dbRef = query(dbRef, where("createUserID", "==", uid))

    await getDocs(query(dbRef, limit(1))).then((result) => {
      if (!result.empty) {
        return resolve({
          success: true,
        })
      }
      return resolve({
        success: false,
      })
    })
  })
}

const getUserQuizSubmission = (
  id: string,
  uid: string
): Promise<{ success: true; data: Submission } | { success: false }> => {
  return new Promise(async (resolve) => {
    await getDoc(doc(db, "Quiz", id, "Submission", uid))
      .then((doc) => {
        if (doc.exists()) {
          return resolve({
            success: true,
            data: {
              id: doc.id,
              ...doc.data(),
            } as Submission,
          })
        }
        return resolve({
          success: false,
        })
      })
      .catch((err) => {
        console.log(err)
        return resolve({
          success: false,
        })
      })
  })
}

const getAllQuizSubmission = (
  id: string
): Promise<
  { success: true; data: UserWithSubmission[] } | { success: false }
> => {
  return new Promise(async (resolve) => {
    await getDocs(
      query(
        collection(db, "Quiz", id, "Submission"),
        orderBy("submitDate", "desc"),
        limit(100)
      )
    )
      .then((docs) => {
        if (!docs.empty) {
          let allData: Promise<UserWithSubmission | null>[] = []

          docs.docs.map((doc) => {
            allData.push(
              new Promise(async (resolve1) => {
                const uid: string = doc.id
                const userState = await getUser(uid)
                if (userState.success) {
                  return resolve1({
                    user: userState.data as OrgStudentUser,
                    submission: {
                      id: uid,
                      ...doc.data(),
                    } as Submission,
                  })
                }
                return resolve1(null)
              })
            )
          })

          Promise.all(allData).then((result) => {
            console.log(result)
            return resolve({
              success: true,
              data: result as unknown as UserWithSubmission[],
            })
          })
        } else {
          return resolve({
            success: false,
          })
        }
      })
      .catch((err) => console.log(err))
  })
}

const getAllUserQuizWithSubmission = (
  uid: string,
  orgID: string,
  orgGroups: string[],
  condition: getUserAssignedQuizCondition
): Promise<
  { success: true; data: QuizWithSubmission[] } | { success: false }
> => {
  return new Promise(async (resolve) => {
    await getUserAssignedQuiz(orgID, orgGroups, condition)
      .then((result) => {
        if (result.success) {
          let allData: Promise<QuizWithSubmission>[] = []

          result.data.map((quiz) => {
            allData.push(
              new Promise(async (resolve1) => {
                const quizID: string = quiz.id as string
                const userState = await getUserQuizSubmission(quizID, uid)
                return resolve1({
                  quiz: quiz,
                  submission: userState.success ? userState.data : null,
                })
              })
            )
          })

          Promise.all(allData).then((result) => {
            console.log(result)
            return resolve({
              success: true,
              data: result as unknown as QuizWithSubmission[],
            })
          })
        } else {
          return resolve({
            success: false,
          })
        }
      })
      .catch((err) => console.log(err))
  })
}

const createQuizSubmission = (
  id: string,
  uid: string
): Promise<{ success: true; data: Submission } | { success: false }> => {
  return new Promise(async (resolve) => {
    const submissionObj = {
      ...defaultAnswerCommon,
      createDate: new Date(),
      createUserID: uid,
    }

    await setDoc(doc(db, "Quiz", id, "Submission", uid), submissionObj)
      .then((doc) => {
        return resolve({
          success: true,
          data: submissionObj,
        })
      })
      .catch((err) => {
        console.log(err)
        return resolve({
          success: false,
        })
      })
  })
}

const submitUserAnswer = (
  quizID: string,
  data: Submission
): Promise<{
  success: boolean
}> => {
  return new Promise(async (resolve) => {
    let updatedSubmission: Submission = { ...data }
    delete updatedSubmission.id

    await updateDoc(
      doc(db, "Quiz", quizID, "Submission", data.id as string),
      objectFieldsToDateType({
        ...updatedSubmission,
        submitDate: new Date(),
        isSubmitted: true,
      })
    )
      .then((res) => {
        return resolve({
          success: true,
        })
      })
      .catch((err) => console.log(err))
  })
}

const startQuiz = async (
  id: string,
  uid: string
): Promise<
  | { success: true; data: Submission }
  | {
      success: false
    }
> => {
  const submissionState = await getUsersQuizSubmission(id, uid)
  if (submissionState.success) {
    return submissionState
  }

  return await createQuizSubmission(id, uid)
}

export {
  getQuiz,
  addQuiz,
  updateQuiz,
  removeQuiz,
  getUserAssignedQuiz,
  countUserVocabQuizHistory,
  getUserAssignedQuizCount,
  getUsersQuizSubmission,
  isUserDoneQuiz,
  getAllQuizSubmission,
  getAllUserQuizWithSubmission,
  createQuizSubmission,
  submitUserAnswer,
  startQuiz,
}
