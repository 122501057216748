import React, { useState } from "react"
import { User } from "../../type/User"
import { ROLE } from "../../enum/APP_TYPE"
import InputText, { InputSize, InputUpdateType } from "./../Input/InputText"
import { OrganizationGroup } from "../../type/Organization"
import { SelectSize } from "../Input/SelectBox"
import MultiSelectBox from "./../Input/MultiSelectBox"
import { useDispatch, useSelector } from "react-redux"
import { orgGroupIDToSelectOptions } from "../../helperFunction/organizationHelper"
import { updateUser } from "../../helperFunction/userDBHelper"
import { showNotification } from "../../redux/headerSlice"
import { updateUserLocally } from "../../redux/userManagementSlice"
import { t } from "i18next"
import { Trans } from "react-i18next"

interface PropsType {
  data: User
}

const EditOrgUser: React.FC<PropsType> = ({ data }) => {
  const dispatch = useDispatch()

  const { isLoading: isOrgLoading, organizationProfile: p } = useSelector(
    (state: any) => state.org
  )

  const [user, setUser] = useState<User>(data)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const updateFormValue = ({ updateType, value }: InputUpdateType) => {
    setUser({
      ...user,
      [updateType]: value,
    })
  }

  const updateSelectedUser = () => {
    setIsLoading(true)
    updateUser(user.id, user).then((result) => {
      setIsLoading(false)
      dispatch(updateUserLocally(user))
      dispatch(showNotification({ message: "Update Successfully", status: 1 }))
    })
  }

  if (user.role === ROLE.ORG_USER) {
    return (
      <section>
        <InputText
          type="text"
          defaultValue={user.firstName}
          updateType="firstName"
          containerStyle="mt-4"
          labelTitle={t("UserManagement.first-name")}
          updateFormValue={updateFormValue}
          size={InputSize.MD}
        />

        <InputText
          type="text"
          defaultValue={user.lastName}
          updateType="lastName"
          containerStyle="mt-4"
          labelTitle={t("UserManagement.last-name")}
          updateFormValue={updateFormValue}
          size={InputSize.MD}
        />

        <MultiSelectBox
          labelTitle={t("UserManagement.assigned-group") as string}
          options={p.orgGroups.map((e: OrganizationGroup) => ({
            label: e.groupName,
            value: e.id,
          }))}
          updateType={"orgGroups"}
          placeholder={"Select Group"}
          updateFormValue={updateFormValue}
          defaultValue={orgGroupIDToSelectOptions(p.orgGroups, user.orgGroups)}
          size={SelectSize.LG}
          containerStyle="mt-2"
        />

        <button
          className="mt-2 btn btn-primary w-full"
          onClick={() => updateSelectedUser()}
          disabled={isLoading}>
          <Trans>ViewOrgUserModal.update</Trans>
        </button>
      </section>
    )
  } else if (user.role === ROLE.ORG_ADMIN) {
  }
  return <div></div>
}
export default EditOrgUser
