import React, { lazy, useLayoutEffect } from "react"
import "./App.css"

import { ReactReduxFirebaseProvider } from "react-redux-firebase"

import initializeApp from "./app/init"

import { Provider, useDispatch } from "react-redux"
import { getReduxStore, getRrfProp } from "./config/firebase-redux"

import RouterManager from "./pages/_app"
// Importing pages

// Initializing different libraries
initializeApp()

function App() {
  return (
    <>
      <Provider store={getReduxStore()}>
        <ReactReduxFirebaseProvider {...getRrfProp()}>
          <RouterManager />
        </ReactReduxFirebaseProvider>
      </Provider>
    </>
  )
}

export default App
