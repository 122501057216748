import React, { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { Article, ARTICLE_LEVEL } from "../../type/Article"
import { getAllArticle } from "../../helperFunction/articleDBHelper"
import {
  dateToDisplayTimeString,
  isEmpty,
  timeConverter,
} from "../../utils/utiltyHelper"
import AdminArticleCard from "../AdminQuiz/AdminArticleCard"
import { DateObj } from "../../type/SystemType"
import {
  setQuizArticle,
  setSelectedQuiz,
} from "../../redux/quizManagementSlice"
import { showNotification } from "../../redux/headerSlice"

interface PropsType {
  closeModal: () => void
  extraObject: object
}

interface ArticleReturnType {
  id: string
  difficult: ARTICLE_LEVEL
}

const SelectArticleModalBody: React.FC<PropsType> = ({
  closeModal,
  extraObject,
}) => {
  const dispatch = useDispatch()
  let history = useNavigate()

  const { selectedQuiz } = useSelector((state: any) => state.quizMarker)
  const articleCardRef = useRef<HTMLDivElement>(null)

  const [allArticles, setAllArticles] = useState<Article[]>([])
  const [isLoaded, setIsLoaded] = useState<boolean>(false)
  const [selectedArticle, setSelectedArticle] = useState<Article | object>({})
  const [hasMore, setHasMore] = useState<boolean>(false)

  const [condition, setCondition] = useState<{
    startAfter?: "" | DateObj | Date
    endAt?: "" | DateObj | Date
  }>({ startAfter: "", endAt: "" })

  useEffect(() => {
    getArticles()
  }, [])

  useEffect(() => {
    getArticles()
  }, [condition])

  const getArticles = () => {
    getAllArticle(condition).then((result) => {
      if (result.success) {
        if (result.data.length > 0) {
          setSelectedArticle(result.data[0])
        }
        setAllArticles(result.data)
        setIsLoaded(true)
        setHasMore(result.hasMore)
      }
    })
  }

  const onSelectArticle = () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const data: ArticleReturnType = articleCardRef.current.returnData()
    if (!isEmpty(selectedArticle) && data) {
      const newQuiz = {
        ...selectedQuiz,
        articleID: data.id as string,
        articleLevel: data.difficult,
      }

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      dispatch(setQuizArticle(selectedArticle[data.difficult].article))
      dispatch(setSelectedQuiz(newQuiz))
      dispatch(showNotification({ message: "Article Added!", status: 1 }))
    }
    closeModal()
  }

  return (
    <>
      <div className="w-full h-full overflow-y-hidden gap-2 grid md:grid-cols-3">
        <div className="md:col-span-1 h-full overflow-x-hidden md:overflow-y-scroll">
          <ul className="p-2 h-44 inline-block md:inline items-center overflow-x-scroll whitespace-nowrap md:whitespace-normal bg-base-100 w-full md:h-full overflow-y-hidden md:overflow-y-scroll">
            {isLoaded &&
              allArticles.map((a: Article, index) => {
                return (
                  <li
                    key={a.id}
                    className=" inline-block whitespace-normal w-64 shadow md:shadow-none md:w-full p-4 hover:bg-orange-50 border-b cursor-pointer"
                    onClick={() => setSelectedArticle(a)}>
                    <div>
                      <h2 className="font-bold text-sm lg:text-lg mb-2 h-20 overflow-hidden">
                        {a.title}
                      </h2>
                      <div className=" badge badge-ghost  text-xs rounded-full bg-orange-100 text-orange-500">
                        {dateToDisplayTimeString(timeConverter(a.createDate))}
                      </div>
                    </div>
                  </li>
                )
              })}

            <li className="inline-block md:inline items-center mx-auto my-auto md:my-2">
              <button
                className="w-full btn-outline btn btn-primary"
                disabled={!hasMore}
                onClick={() =>
                  setCondition({
                    startAfter: allArticles[allArticles.length - 1].createDate,
                  })
                }>
                More
              </button>
            </li>
          </ul>
        </div>
        <div className="md:col-span-2 overflow-hidden">
          {!isEmpty(selectedArticle) && (
            <AdminArticleCard
              ref={articleCardRef}
              data={selectedArticle as Article}
            />
          )}
        </div>
      </div>

      <div className="modal-action inline-block float-right text-right">
        <div className="w-full block float-right">
          <button className="btn btn-ghost" onClick={() => closeModal()}>
            Cancel
          </button>
          <button
            className="btn text-white btn-primary px-6"
            onClick={() => {
              onSelectArticle()
            }}>
            Confirm
          </button>
        </div>
      </div>
    </>
  )
}
export default SelectArticleModalBody
