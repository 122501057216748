import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"
import {
  getUser,
  getUserCollected,
  updateUserLoginRecord,
} from "../helperFunction/userDBHelper"
import { objectDateToString } from "../utils/utiltyHelper"
import { OrgStudentUser, WordObject } from "../type/User"
import { SwipeType } from "../components/BookMark/Card"
import { v4 as uuidv4 } from "uuid"
import { ROLE } from "../enum/APP_TYPE"
import { reorderWordByForgettingCurve } from "../helperFunction/bookmarkHelper"

const updateCollected = (
  allCollected: WordObject[],
  oldItem: WordObject,
  newItem: WordObject
): WordObject[] => {
  return allCollected.map((item) => (item.id === oldItem.id ? newItem : item))
}

const userCollectionDocsToArr = (uCollection: WordObject[]): WordObject[] => {
  const allWordObj: WordObject[] = []

  uCollection.map((e) => {
    allWordObj.push({
      ...e,
      id: uuidv4(),
    } as unknown as WordObject)
  })

  return allWordObj
}

const updateWord = (
  collected: WordObject[],
  word: WordObject,
  action: SwipeType
): WordObject[] => {
  const currentDate = new Date()

  switch (action) {
    case SwipeType.LIKE:
      // For "LIKE" action, set 7 days back from the current date
      currentDate.setDate(currentDate.getDate() - 7)
      return collected.map((item) =>
        item.name === word.name ? { ...word, lastAccess: currentDate } : item
      )

    case SwipeType.DELETE:
      // For "DELETE" action, make lastAccess to "-1" in string
      return collected.map((item) =>
        item.name === word.name ? { ...word, lastAccess: "-1" } : item
      )

    case SwipeType.SKIP:
      // For "SKIP" action, replace the date part with the current date in string format
      return collected.map((item) =>
        item.name === word.name ? { ...word, lastAccess: currentDate } : item
      )

    default:
      return collected
  }
}

export interface UserState {
  profile: OrgStudentUser | object
  isLoadingProfile: boolean
  collected: WordObject[]
  isLoadingCollected: boolean
  selectedCollected: WordObject[]
  isLoading: boolean
  hasChange: boolean
}

export const getUserProfileThunk = createAsyncThunk(
  "user/getProfile",
  async (uid: string) => {
    return objectDateToString(await getUser(uid))
  }
)

export const getUserCollectionThunk = createAsyncThunk(
  "user/getCollection",
  async (uid: string) => {
    return objectDateToString(await getUserCollected(uid))
  }
)

export const userSlice = createSlice({
  name: "user",
  initialState: {
    profile: {},
    isLoadingProfile: false,
    collected: [],
    isLoadingCollected: false,
    selectedCollected: [],
    isLoading: false,
    hasChange: false,
  } as UserState,
  reducers: {
    setUserCollected: (state, action: PayloadAction<WordObject[]>) => {
      state.collected = action.payload
    },
    updateUserCollected: (
      state,
      action: PayloadAction<{
        oldItem: WordObject
        newItem: WordObject
      }>
    ) => {
      state.collected = updateCollected(
        state.collected,
        action.payload.oldItem,
        action.payload.newItem
      )
      state.isLoadingCollected = false
    },
    setSelectedCollected: (state, action: PayloadAction<WordObject[]>) => {
      state.selectedCollected = reorderWordByForgettingCurve(action.payload)
      state.isLoadingCollected = false
    },
    addWordToCollected: (state, action: PayloadAction<WordObject>) => {
      state.collected = state.collected.concat(action.payload)
      state.hasChange = !state.hasChange
    },
    updateWordToCollected: (
      state,
      action: PayloadAction<{
        word: WordObject
        action: SwipeType
      }>
    ) => {
      state.collected = updateWord(
        state.collected,
        action.payload.word,
        action.payload.action
      )
      state.hasChange = !state.hasChange
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserProfileThunk.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getUserProfileThunk.fulfilled, (state, action) => {
        if (
          action.payload.success &&
          action.payload.data.role === ROLE.ORG_USER
        ) {
          //update lastLogin record
          updateUserLoginRecord(action.payload.data)
          state.profile = {
            ...action.payload.data,
            //update lastLogin record locally
            lastLogin: new Date().toISOString(),
          }
        }
        state.isLoading = false
      })
      .addCase(getUserProfileThunk.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(getUserCollectionThunk.pending, (state) => {
        state.isLoadingCollected = true
      })
      .addCase(getUserCollectionThunk.fulfilled, (state, action) => {
        //only for student type
        if (action.payload.success) {
          state.collected = userCollectionDocsToArr(action.payload.data)
        }
        state.isLoadingCollected = false
      })
      .addCase(getUserCollectionThunk.rejected, (state) => {
        state.isLoadingCollected = false
      })
  },
})

export const {
  setUserCollected,
  updateUserCollected,
  setSelectedCollected,
  addWordToCollected,
  updateWordToCollected,
} = userSlice.actions

export default userSlice.reducer
