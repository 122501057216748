module.exports = Object.freeze({
  MODAL_BODY_TYPES: {
    USER_DETAIL: "USER_DETAIL",
    LEAD_ADD_NEW: "LEAD_ADD_NEW",
    CONFIRMATION: "CONFIRMATION",
    DEFAULT: "",
    ADD_WORD_MODAL: "ADD_WORD_MODAL",
    CREATE_NEW_QUIZ_MODAL: "CREATE_NEW_QUIZ_MODAL",
    CREATE_NEW_ORG_GROUP: "CREATE_NEW_ORG_GROUP",
    CREATE_NEW_ORG_USER: "CREATE_NEW_ORG_USER",
    ISSUE_NEW_QUIZ_MODAL: "ISSUE_NEW_QUIZ_MODAL",
    CONFIRM_QUIZ_SUBMISSION: "CONFIRM_QUIZ_SUBMISSION",
    SELECT_ARTICLE: "SELECT_ARTICLE",
    MOBILE_ARTICLE_HELPER: "MOBILE_ARTICLE_HELPER",
    DOWNLOAD_QUIZ_PDF: "DOWNLOAD_QUIZ_PDF",
    CLONE_QUIZ_MODAL: "CLONE_QUIZ_MODAL",
    REMOVE_QUIZ_MODAL: "REMOVE_QUIZ_MODAL",
    VIEW_ORG_USER_MODAL: "VIEW_ORG_USER_MODAL",
    STUDENT_VOCAB_QUIZ_CREATION: "STUDENT_VOCAB_QUIZ_CREATION",
    VIEW_QUIZ_RESULT_MODAL: "VIEW_QUIZ_RESULT_MODAL",
  },

  RIGHT_DRAWER_TYPES: {
    NOTIFICATION: "NOTIFICATION",
    CALENDAR_EVENTS: "CALENDAR_EVENTS",
  },

  CONFIRMATION_MODAL_CLOSE_TYPES: {
    LEAD_DELETE: "LEAD_DELETE",
  },
})
