import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import InputText, { InputSize, InputUpdateType } from "../Input/InputText"
import { setSelectedText } from "../../redux/toolsSlice"
import {
  defaultOrganizationGroup,
  OrganizationGroup,
} from "../../type/Organization"
import { v4 as uuidv4 } from "uuid"
import { showNotification } from "../../redux/headerSlice"
import { createNewGroup } from "../../redux/orgManagementSlice"
import { t } from "i18next"
import { Trans } from "react-i18next"

interface PropsType {
  closeModal: () => void
}

const CreateNewOrgGroupModalBody: React.FC<PropsType> = ({ closeModal }) => {
  const dispatch = useDispatch()
  const { isLoading: isOrgLoading, organizationProfile: p } = useSelector(
    (state: any) => state.org
  )

  const [newGroup, setNewGroup] = useState<OrganizationGroup>({
    ...defaultOrganizationGroup,
    id: uuidv4(),
    schoolYear: `${new Date().getFullYear() % 100}/${
      (new Date().getFullYear() + 1) % 100
    }`,
  })

  const saveNewGroup = () => {
    dispatch(createNewGroup(newGroup))
    dispatch(showNotification({ message: "New Group Added!", status: 1 }))
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(closeModal())
  }

  const updateFormValue = ({ updateType, value }: InputUpdateType) => {
    setNewGroup({
      ...newGroup,
      [updateType]: value,
    })
  }

  return (
    <>
      <InputText
        type="text"
        defaultValue={newGroup.groupName}
        updateType="groupName"
        containerStyle="mt-4"
        labelTitle={t("CreateNewOrgGroup.group-name")}
        updateFormValue={updateFormValue}
        onTimeout={(str: string) => dispatch(setSelectedText(str))}
        size={InputSize.LG}
      />

      <div className="modal-action">
        <button className="btn btn-ghost" onClick={() => closeModal()}>
          <Trans>CreateNewOrgGroup.cancel</Trans>
        </button>
        <button
          className="btn text-white btn-primary px-6"
          onClick={() => saveNewGroup()}>
          <Trans>CreateNewOrgGroup.save</Trans>
        </button>
      </div>
    </>
  )
}

export default CreateNewOrgGroupModalBody
