export interface Organization {
  id?: string
  contactPerson: {
    email: string
    fullName: string
    role: string
    tel: string
  }
  createDate: Date
  currentUserCount: number
  orgGroups: OrganizationGroup[]
  orgID: string
  orgName: string
  subscriptionEnd: Date
  subscriptionStart: Date
  userCount: number
}

export interface OrganizationGroup {
  id: string
  schoolYear: string
  groupName: string
}

export const defaultOrganizationGroup = {
  id: "",
  schoolYear: "",
  groupName: "",
}

export const defaultOrganization: Organization = {
  id: "",
  contactPerson: {
    email: "",
    fullName: "",
    role: "",
    tel: "",
  },
  createDate: new Date(),
  currentUserCount: 0,
  orgGroups: [],
  orgID: "",
  orgName: "",
  subscriptionEnd: new Date(),
  subscriptionStart: new Date(),
  userCount: 0,
}

export const exampleOrganization: Organization = {
  id: "dganga464",
  contactPerson: {
    email: "team@look4kol.com",
    fullName: "WONG Kai Yin",
    role: "CTO",
    tel: "69029701",
  },
  createDate: new Date(),
  currentUserCount: 10,
  orgGroups: [
    {
      id: "8d666bdf-7f7b-43db-835a-ae3b4a34cc57",
      schoolYear: "21/22",
      groupName: "1A",
    },
    {
      id: "8d666bdf-7f7b-43db-835a-ae3b4a34cc12",
      schoolYear: "22/23",
      groupName: "2A",
    },
  ],
  orgID: "look4kol",
  orgName: "Happyjai Limited",
  subscriptionEnd: new Date(),
  subscriptionStart: new Date(),
  userCount: 1000,
}
