import React, { useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useReactToPrint } from "react-to-print"
import { QuizType } from "../../type/Quiz"
import AdminArticleCard from "../AdminQuiz/AdminArticleCard"
import { Question } from "../../type/Question"
import UserQuestionCardManager from "../UserQuiz/UserQuestionCardManager"

interface PropsType {
  closeModal: () => void
}

const DownloadQuizPDFModalBody: React.FC<PropsType> = ({ closeModal }) => {
  const componentRef = useRef()
  const handlePrint = useReactToPrint({
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    content: () => componentRef.current,
  })

  const auth = useSelector((state: any) => {
    return state.firebase.auth
  })

  const { isLoading, selectedQuiz } = useSelector(
    (state: any) => state.quizMarker
  )

  return (
    <>
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment*/}
      {/* @ts-ignore*/}
      <div ref={componentRef} className="h-full overflow-y-scroll">
        {/*//article section*/}
        {selectedQuiz.type === QuizType.READING_COMPREHENSION && (
          <section className="bg-gray-100 md:p-4">
            {selectedQuiz.articleID && (
              <AdminArticleCard articleID={selectedQuiz.articleID} />
            )}
          </section>
        )}

        <section>
          {selectedQuiz.questions &&
            selectedQuiz.questions.map((q: Question, index: number) => {
              return (
                <UserQuestionCardManager key={q.id} data={q} index={index} />
              )
            })}
        </section>
      </div>

      <div className="modal-action">
        <button className="btn btn-ghost" onClick={() => closeModal()}>
          Cancel
        </button>
        <button
          className="btn text-white btn-primary px-6"
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          onClick={handlePrint}>
          Download PDF
        </button>
      </div>
    </>
  )
}

export default DownloadQuizPDFModalBody
