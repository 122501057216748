import React from "react"
import InformationCircleIcon from "@heroicons/react/24/outline/InformationCircleIcon"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import classNames from "classnames"

export enum DatePickerSize {
  SM,
  MD,
  LG,
  XL,
}

interface DatePickerProps {
  labelTitle: string
  labelDescription?: string
  selectedDate?: Date
  containerStyle?: string
  labelStyle?: string
  updateType: string
  updateFormValue: (data: { updateType: string; value: Date }) => void
  size?: DatePickerSize
  minDate?: Date
  maxDate?: Date
}

const CustomDatePicker: React.FC<DatePickerProps> = (props) => {
  const {
    labelTitle,
    labelDescription,
    selectedDate,
    containerStyle,
    labelStyle,
    updateType,
    updateFormValue,
    size,
    minDate,
    maxDate,
  } = props

  const updateDate = (newDate: Date) => {
    updateFormValue({ updateType, value: newDate })
  }

  return (
    <div className={`inline-block w-full my-2 ${containerStyle}`}>
      <label className={`label ${labelStyle}`}>
        <div className="label-text">
          {labelTitle}
          {labelDescription && (
            <div className="tooltip tooltip-right" data-tip={labelDescription}>
              <InformationCircleIcon className="w-4 h-4" />
            </div>
          )}
        </div>
      </label>

      <DatePicker
        selected={selectedDate}
        onChange={updateDate}
        className={classNames("input input-bordered w-full", {
          "input-sm": size === DatePickerSize.SM,
          "input-md": size === DatePickerSize.MD,
          "input-lg": size === DatePickerSize.LG,
          "text-2xl": size === DatePickerSize.XL,
        })}
        // Customize date format if needed
        dateFormat="dd/MM/yyyy-h:mm"
        showTimeSelect
        timeFormat="HH:mm"
        timeIntervals={15}
        minDate={minDate}
        maxDate={maxDate}
      />
    </div>
  )
}

export default CustomDatePicker
