import { DateObj } from "./SystemType"

export interface UserArticle {
  level: ARTICLE_LEVEL
  content: string
  accessDate: Date | DateObj
  goodSentences: GoodSentences[]
  difficultWords: string[]
}

export enum ARTICLE_ADD_ON {
  DICTIONARY = "Dictionary",
  WORD_LEVEL = "Word Level",
  DIFFICULT_WORD = "Difficult Word",
  GOOD_SENTENCE = "Recommended Sentence",
}

export enum ARTICLE_LEVEL {
  EASY = "easy",
  MEDIUM = "medium",
  HARD = "hard",
}

export interface GoodSentences {
  sentence: string
  reason: string
  reasonCHI?: string
}

export interface ConfusedWord {
  word: string
  confusedWord: string
}

interface ArticleLevelContent {
  article: string
  difficultWords: string[]
  confusedWord: ConfusedWord[]
  goodSentences: GoodSentences[]
}

export interface Article {
  id?: string
  isPublish: boolean
  category?: ArticleCategory | "" | null
  easy: ArticleLevelContent
  medium: ArticleLevelContent
  hard: ArticleLevelContent
  createDate: Date | DateObj
  url: string
  title: string
  titleCHI: string
  publishDate: Date | DateObj
  lastUpdate: Date | DateObj
  image: string
}

export enum ArticleCategory {
  // Political news, government, and policy
  POLITICS = "0",
  // Business, finance, economy, and industry news
  BUSINESS = "1",
  // Celebrity news, movies, TV, music, and arts
  ENTERTAINMENT = "2",
  // Sports news, scores, and updates
  SPORTS = "3",
  // Tech news, gadgets, innovation, and science,
  TECHNOLOGY = "4",
}

export const ArticleCategorySelect = [
  { label: "ArticleCategory.politics", value: "0" },
  { label: "ArticleCategory.business", value: "1" },
  { label: "ArticleCategory.entertainment", value: "2" },
  { label: "ArticleCategory.sports", value: "3" },
  { label: "ArticleCategory.technology", value: "4" },
]

export enum VOCAB_LIST {
  EASY,
  MEDIUM,
  HARD,
  PHRASAL_VERB,
}
