export enum ALERT_TYPE {
  INFO,
  WARNING,
  ERROR,
  REQUEST,
  PAYMENT,
}

export enum BUTTON_TYPE {
  TEXT_ONLY,
  WITH_ICON,
  HELP_BTN,
  NONE,
}
