import React, { useState } from "react"
import Subtitle from "../Typography/Subtitle"
import classNames from "classnames"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons"

interface PropsType {
  title: string
  children: JSX.Element | JSX.Element[]
  topMargin: string
  TopSideButtons?: JSX.Element | JSX.Element[]
  BackButtons?: JSX.Element | JSX.Element[]
  isCard?: boolean
  height?: string
  isHiddenScrollBar?: boolean
  useCollapse?: boolean
  customPadding?: string
}

const TitleCard: React.FC<PropsType> = ({
  title,
  children,
  topMargin,
  TopSideButtons,
  BackButtons,
  isCard,
  height,
  isHiddenScrollBar,
  useCollapse,
  customPadding,
}) => {
  const [isCollapseOpen, setIsCollapseOpen] = useState<boolean>(true)

  return (
    <div
      className={classNames(
        "collapse  card px-0 rounded-lg pt-0 card-compact w-full h-full bg-base-100 ",
        topMargin || "mt-6",
        {
          shadow: isCard,
          [`collapse-open ${height}`]: isCollapseOpen || !useCollapse,
          "overflow-hidden": isHiddenScrollBar,
          [`max-${height} relative `]: height,
        }
      )}>
      {/* Title for Card */}
      <div
        className={classNames("collapse-title pl-3 pr-2 cursor-pointer")}
        onClick={() => {
          if (useCollapse) {
            setIsCollapseOpen(!isCollapseOpen)
          }
        }}>
        <Subtitle
          styleClass={classNames("", {
            " inline-block": TopSideButtons,
            "sticky top-0 bg-white z-5": height,
          })}>
          <div className="flex gap-2">
            {/* Top side button, show only if present */}
            {BackButtons && (
              <div className="flex-none inline-block ">{BackButtons}</div>
            )}

            <div className="flex-grow">{title}</div>

            {/* Top side button, show only if present */}
            {TopSideButtons && (
              <div className="flex-none inline-block ">{TopSideButtons}</div>
            )}

            {useCollapse && (
              <button className="btn btn-sm btn-ghost btn-circle">
                <FontAwesomeIcon
                  icon={isCollapseOpen ? faChevronUp : faChevronDown}
                  className="text-gray-500 font-bold my-auto"
                />
              </button>
            )}
          </div>
        </Subtitle>
      </div>
      <div
        className={classNames("collapse-content p-0", {
          "border-gray-100 border-t-2": isCollapseOpen || !useCollapse,
        })}>
        {/** Card Body */}
        <div
          className={classNames(
            "h-full w-full bg-base-100 overflow-x-hidden overflow-y-scroll",
            {
              "px-2  pt-2 pb-1": !customPadding,
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              [customPadding]: customPadding,
            }
          )}>
          {children}
        </div>
      </div>
    </div>
  )
}

export default TitleCard
