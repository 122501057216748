import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import QuizCard from "../components/QuizCard"
import { Quiz } from "../type/Quiz"
import Alert from "../components/Alert"
import { ALERT_TYPE, BUTTON_TYPE } from "../enum/ALERT_SYSYEM"
import { Trans } from "react-i18next"
import { createSearchParams, useLocation, useNavigate } from "react-router-dom"
import { ListQuizViewItem } from "../pages/protected/ListQuiz"
import classNames from "classnames"
import { timeConverter, updateSearchParams } from "../utils/utiltyHelper"

interface PropsType {
  viewType: ListQuizViewItem
  quizzes: Quiz[]
  isLoaded: boolean
  hasMore: boolean
}

const ListQuizComponent: React.FC<PropsType> = ({
  viewType,
  quizzes,
  isLoaded,
  hasMore,
}) => {
  let history = useNavigate()

  return (
    <div className="relative">
      <div className="absolute overflow-hidden z-0 w-full top-0 h-96">
        <svg
          className="h-full"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1440 320">
          <path
            fill="#ffedd6"
            fillOpacity="1"
            d="M0,192L26.7,186.7C53.3,181,107,171,160,192C213.3,213,267,267,320,282.7C373.3,299,427,277,480,261.3C533.3,245,587,235,640,208C693.3,181,747,139,800,144C853.3,149,907,203,960,192C1013.3,181,1067,107,1120,90.7C1173.3,75,1227,117,1280,117.3C1333.3,117,1387,75,1413,53.3L1440,32L1440,0L1413.3,0C1386.7,0,1333,0,1280,0C1226.7,0,1173,0,1120,0C1066.7,0,1013,0,960,0C906.7,0,853,0,800,0C746.7,0,693,0,640,0C586.7,0,533,0,480,0C426.7,0,373,0,320,0C266.7,0,213,0,160,0C106.7,0,53,0,27,0L0,0Z"></path>
        </svg>
      </div>

      <div className="relative z-1  p-4 xl:p-8 mx-auto max-w-5xl ">
        <h1 className="text-3xl font-bold block">
          <Trans>QuizPage.title</Trans>
        </h1>
        <div className="mt-4 flex gap-2">
          <button
            className={classNames("btn w-20 rounded-full ", {
              "btn-primary": viewType === ListQuizViewItem.TEACHER,
              "btn-ghost !border-orange-400 !border-2 !text-orange-500":
                viewType !== ListQuizViewItem.TEACHER,
            })}
            onClick={() => {
              const searchParams = updateSearchParams(
                "",
                "type",
                ListQuizViewItem.TEACHER
              )

              history({
                pathname: location.pathname,
                search: searchParams,
              })
            }}>
            <Trans>QuizPage.school</Trans>
          </button>

          <button
            className={classNames("btn w-20 rounded-full ", {
              "btn-primary": viewType === ListQuizViewItem.USER,
              "btn-ghost !border-orange-400 !border-2 !text-orange-500":
                viewType !== ListQuizViewItem.USER,
            })}
            onClick={() => {
              const searchParams = updateSearchParams(
                "",
                "type",
                ListQuizViewItem.USER
              )

              history({
                pathname: location.pathname,
                search: searchParams,
              })
            }}>
            <Trans>QuizPage.user</Trans>
          </button>
        </div>
        {/* List ListQuizComponent Card*/}
        <section className="my-8">
          <div className="grid gap-2 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3">
            {isLoaded &&
              quizzes.map((q: Quiz, index: number) => {
                return <QuizCard key={q.id} data={q} />
              })}

            {isLoaded && quizzes.length === 0 && (
              <Alert
                title={"CustomTable.isLastRecord"}
                alertType={ALERT_TYPE.INFO}
                buttonType={{
                  type: BUTTON_TYPE.NONE,
                }}
              />
            )}

            {!isLoaded && <span>Loading</span>}
            {isLoaded && hasMore && (
              <button
                className="w-full my-2 btn btn-primary"
                disabled={!hasMore}
                onClick={() => {
                  const startAfter = timeConverter(
                    quizzes[quizzes.length - 1].createDate
                  ).toISOString()
                  const searchParams = updateSearchParams(
                    location.search,
                    "startAfter",
                    startAfter
                  )

                  history({
                    pathname: location.pathname,
                    search: searchParams,
                  })
                }}>
                <Trans>QuizMarker.more</Trans>
              </button>
            )}
          </div>
        </section>
      </div>
    </div>
  )
}
export default ListQuizComponent
