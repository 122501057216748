import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import InputText, {
  InputSize,
  InputUpdateType,
} from "../../components/Input/InputText"
import { showNotification } from "../../redux/headerSlice"
import { defaultQuiz, Quiz, QuizType } from "../../type/Quiz"
import { v4 as uuidv4 } from "uuid"
import SelectBox, { SelectOption, SelectSize } from "../Input/SelectBox"
import { useNavigate } from "react-router-dom"
import { addQuiz } from "../../helperFunction/quizDBHelper"
import { addNewQuiz } from "../../redux/quizManagementSlice"
import { t } from "i18next"
import { Trans } from "react-i18next"

interface PropsType {
  closeModal: () => void
}

const CreateNewQuizModalBody: React.FC<PropsType> = ({ closeModal }) => {
  const dispatch = useDispatch()
  let history = useNavigate()

  const auth = useSelector((state: any) => {
    return state.firebase.auth
  })

  const { isLoading: isOrgLoading, organizationProfile: p } = useSelector(
    (state: any) => state.org
  )

  const { isLoading: isQuizLoading, quizzes } = useSelector(
    (state: any) => state.quizMarker
  )

  const [newQuiz, setNewQuiz] = useState<Quiz>(defaultQuiz)

  const saveNewQuiz = () => {
    const quizID = uuidv4()
    const newQ = {
      ...newQuiz,
      id: quizID,
      orgID: auth.orgID,
      createUserID: auth.uid,
    }

    addQuiz(newQ).then((result) => {
      if (result.success) {
        dispatch(addNewQuiz(newQ))
        dispatch(
          showNotification({
            message: "New Quiz Added!",
            status: 1,
          })
        )
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        dispatch(closeModal())
        history(`/management/quiz/${quizID}`)
      }
    })
  }

  const updateFormValue = ({ updateType, value }: InputUpdateType) => {
    setNewQuiz({
      ...newQuiz,
      [updateType]: value,
    })
  }

  const QuizTypeSelectOption: SelectOption[] = [
    { label: t("CreateNewQuizModal.normal"), value: QuizType.NORMAL },
    {
      label: t("CreateNewQuizModal.reading-comprehension"),
      value: QuizType.READING_COMPREHENSION,
    },
  ]

  if (!isOrgLoading && p) {
    return (
      <>
        <div className="">
          <InputText
            type="text"
            defaultValue={newQuiz.title}
            updateType="title"
            containerStyle="mt-4"
            labelTitle={t("CreateNewQuizModal.quiz-name")}
            updateFormValue={updateFormValue}
            size={InputSize.MD}
          />
        </div>

        <div>
          <SelectBox
            labelTitle={t("CreateNewQuizModal.quiz-type") as string}
            options={QuizTypeSelectOption}
            updateType={"type"}
            updateFormValue={updateFormValue}
            size={SelectSize.MD}
            containerStyle="my-2"
          />
        </div>

        <div className="modal-action">
          <button className="btn btn-ghost" onClick={() => closeModal()}>
            <Trans>CreateNewOrgGroup.cancel</Trans>
          </button>
          <button
            className="btn text-white btn-primary px-6"
            onClick={() => saveNewQuiz()}>
            <Trans>CreateNewOrgGroup.save</Trans>
          </button>
        </div>
      </>
    )
  }
  return <div></div>
}

export default CreateNewQuizModalBody
