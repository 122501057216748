import React, { useState } from "react"
import { MODAL_BODY_TYPES } from "../utils/globalConstantUtil"
import { useSelector, useDispatch } from "react-redux"
import classNames from "classnames"

import { closeModal } from "../redux/modalSlice"
import AddNewWordModalBody from "../components/ModalComponent/AddNewWordModalBody"
import CreateNewQuizModalBody from "../components/ModalComponent/CreateNewQuizModalBody"
import IssueNewQuizModalBody from "../components/ModalComponent/IssueNewQuizModalBody"
import CreateNewOrgGroupModalBody from "../components/ModalComponent/CreateNewOrgGroupModalBody"
import CreateNewOrgUserModalBody from "../components/ModalComponent/CreateNewOrgUserModalBody"
import ConfirmQuizSubmissionBody from "../components/ModalComponent/ConfirmQuizSubmissionBody"
import SelectArticleModalBody from "../components/ModalComponent/SelectArticleModalBody"
import MobileArticleHelperModel from "../components/ModalComponent/MobileArticleHelperModel"
import DownloadQuizPDFModalBody from "../components/ModalComponent/DownloadQuizPDFModalBody"
import ViewOrgUserModal from "../components/ModalComponent/ViewOrgUserModal"
import StudentVocabQuizCreationModalBody from "../components/ModalComponent/StudentVocabQuizCreationModalBody"
import CloneQuizModalBody from "../components/ModalComponent/CloneQuizModal"
import RemoveQuizModalBody from "../components/ModalComponent/RemoveQuizModal"
import ViewQuizResultModalBody from "../components/ModalComponent/ViewQuizResultModalBody"

export enum ModalSize {
  MD,
  LG,
  XL,
}

export interface ModalPropsType {
  isOpen: boolean
  bodyType?: string
  size?: ModalSize
  extraObject: any
  title: string
  isNew: boolean
}

const ModalLayout: React.FC<ModalPropsType> = () => {
  const dispatch = useDispatch()
  const { isOpen, bodyType, size, extraObject, title, isNew } = useSelector(
    (state: any) => state.modal
  )

  const close = (e?: any) => {
    dispatch(closeModal(e))
  }

  return (
    <>
      {/* The button to open modal */}

      {/* Put this part before </body> tag */}
      <div
        className={classNames("modal ", {
          "modal-open": isOpen,
        })}>
        <div
          className={classNames(
            "modal-box flex flex-col overflow-hidden relative",
            { "max-w-5xl": size === ModalSize.LG },
            { "max-w-7xl h-full": size === ModalSize.XL }
          )}
          style={{ paddingTop: 0 }}>
          <div className="sticky top-0 z-10 h-16 p-4 bg-white">
            <button
              className="btn btn-sm btn-circle absolute right-0 top-4"
              onClick={() => close()}>
              ✕
            </button>
            <h3 className="font-semibold text-2xl pb-6 text-center">{title}</h3>
          </div>

          {/* Loading modal body according to different modal type */}
          {
            {
              [MODAL_BODY_TYPES.DEFAULT]: <div></div>,
              [MODAL_BODY_TYPES.ADD_WORD_MODAL]: (
                <AddNewWordModalBody
                  closeModal={close}
                  extraObject={extraObject}
                />
              ),
              [MODAL_BODY_TYPES.CREATE_NEW_QUIZ_MODAL]: (
                <CreateNewQuizModalBody closeModal={close} />
              ),
              [MODAL_BODY_TYPES.CREATE_NEW_ORG_GROUP]: (
                <CreateNewOrgGroupModalBody closeModal={close} />
              ),
              [MODAL_BODY_TYPES.CREATE_NEW_ORG_USER]: (
                <CreateNewOrgUserModalBody closeModal={close} />
              ),
              [MODAL_BODY_TYPES.ISSUE_NEW_QUIZ_MODAL]: (
                <IssueNewQuizModalBody
                  extraObject={extraObject}
                  closeModal={close}
                />
              ),
              [MODAL_BODY_TYPES.CONFIRM_QUIZ_SUBMISSION]: (
                <ConfirmQuizSubmissionBody closeModal={close} />
              ),
              [MODAL_BODY_TYPES.SELECT_ARTICLE]: (
                <SelectArticleModalBody
                  extraObject={extraObject}
                  closeModal={close}
                />
              ),
              [MODAL_BODY_TYPES.MOBILE_ARTICLE_HELPER]: (
                <MobileArticleHelperModel
                  extraObject={extraObject}
                  closeModal={close}
                />
              ),
              [MODAL_BODY_TYPES.DOWNLOAD_QUIZ_PDF]: (
                <DownloadQuizPDFModalBody closeModal={close} />
              ),
              [MODAL_BODY_TYPES.VIEW_ORG_USER_MODAL]: (
                <ViewOrgUserModal closeModal={close} />
              ),
              [MODAL_BODY_TYPES.STUDENT_VOCAB_QUIZ_CREATION]: (
                <StudentVocabQuizCreationModalBody
                  closeModal={close}
                  extraObject={extraObject}
                />
              ),
              [MODAL_BODY_TYPES.CLONE_QUIZ_MODAL]: (
                <CloneQuizModalBody
                  closeModal={close}
                  extraObject={extraObject}
                />
              ),
              [MODAL_BODY_TYPES.REMOVE_QUIZ_MODAL]: (
                <RemoveQuizModalBody
                  closeModal={close}
                  extraObject={extraObject}
                />
              ),
              [MODAL_BODY_TYPES.VIEW_QUIZ_RESULT_MODAL]: (
                <ViewQuizResultModalBody
                  closeModal={close}
                  extraObject={extraObject}
                />
              ),
            }[bodyType]
          }
        </div>
      </div>
    </>
  )
}

export default ModalLayout
