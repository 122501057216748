import { v4 as uuidv4 } from "uuid"

export type Question = MC | ShortQuestion | OrderQuestion | FBQuestion

export enum QuestionType {
  MC = "MC",
  SQ = "SQ",
  OQ = "OQ",
  // fill in the blanks
  FB = "FB",
}

export interface QuestionCommon {
  id?: string
  createUserID: string
  createDate: Date
  questionBaseID: string
}

export type ChatGPTQuestion = chatGPTMC | chatGPTFB

export type chatGPTMC = {
  type: QuestionType.MC
  question: string
  options: MCOptionText[]
  // can be multiple
  answer: string[]
  isMultiple: boolean
}

export type chatGPTFB = {
  type: QuestionType.FB
  question: string
  answer: string[]
}

export type MC = {
  type: QuestionType.MC
  question: string
  options: MCOption[]
  // can be multiple
  answer: string[]
  isMultiple: boolean
  points: number
  reason?: string
  reasonCHI?: string
} & QuestionCommon

export const MCDefault: MC = {
  type: QuestionType.MC,
  question: "unnamed question",
  options: [],
  // can be multiple
  answer: [],
  isMultiple: false,
  points: 5,
  id: "",
  createUserID: "",
  createDate: new Date(),
  questionBaseID: "",
}

export const ShortQuestionDefault: ShortQuestion = {
  type: QuestionType.SQ,
  id: uuidv4(),
  createUserID: "",
  createDate: new Date(),
  questionBaseID: uuidv4(),
  // quill item (allowing image
  question: "unnamed question",
  answer: "",
  keywords: [],
  marking: [],
  points: 0,
}

export type MCOption = MCOptionText | MCOptionImage

export const MCOptionDefault: MCOption = {
  value: "",
  label: "",
}

export interface MCOptionText {
  value: string
  label: string
}

export interface MCOptionImage {
  value: string
  label: string
  img: string
}

export type ShortQuestion = {
  type: QuestionType.SQ
  // quill item (allowing image)
  question: string
  answer: string
  keywords: string[]
  marking: Marketing[]
  points: number
} & QuestionCommon

export interface Marketing {
  requirement: string
  score: number
}

export type OrderQuestion = {
  type: QuestionType.OQ
  question: string
  options: MCOption[]
  answer: string[]
  points: number
} & QuestionCommon

export type FBQuestion = {
  type: QuestionType.FB
  question: string
  answer: string[]
  points: number
} & QuestionCommon

export const FBDefault: FBQuestion = {
  type: QuestionType.FB,
  question: "unnamed question",
  answer: [],
  points: 5,
  id: "",
  createUserID: "",
  createDate: new Date(),
  questionBaseID: "",
}

export const exampleShortQuestion: ShortQuestion = {
  type: QuestionType.SQ,
  id: uuidv4(),
  createUserID: "123",
  createDate: new Date(),
  questionBaseID: uuidv4(),
  // quill item (allowing image
  question: "<p>What is Apple</p>",
  answer: "蘋果樹是薔薇科蘋果亞科蘋果屬植物",
  keywords: ["薔薇科", "水果"],
  marking: [
    {
      requirement: "提及水果100",
      score: 100,
    },
  ],
  points: 250,
}

export const exampleMCQuestion: Question = {
  type: QuestionType.MC,
  id: uuidv4(),
  createUserID: "",
  createDate: new Date(),
  questionBaseID: uuidv4(),
  question: "A for Apple, B for what",
  options: [
    {
      value: uuidv4(),
      label: "Billy",
    },
    {
      value: uuidv4(),
      label: "Boy",
    },
    {
      value: uuidv4(),
      label: "Basketball",
    },
    {
      value: uuidv4(),
      label: "Banana",
    },
  ],
  // can be multiple
  answer: [],
  isMultiple: true,
  points: 0,
}
