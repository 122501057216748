import { configureStore } from "@reduxjs/toolkit"

import firebase from "./firebase"
import headerSlice from "../redux/headerSlice"
import modalSlice from "../redux/modalSlice"
import rightDrawerSlice from "../redux/rightDrawerSlice"
import newsSlice from "../redux/newsSlice"
import LoginManager from "../redux/firebaseSlice"
import systemSlice from "../redux/systemSlice"
import toolsSlice from "../redux/toolsSlice"
import userSlice from "../redux/userSlice"
import quizSlice from "../redux/quizSlice"
import quizManagementSlice from "../redux/quizManagementSlice"
import orgManagementSlice from "../redux/orgManagementSlice"
import userManagementSlice from "../redux/userManagementSlice"
import assistantChatSlice from "../redux/assistantChatSlice"
import ttsManager from "../redux/ttsManager"

// Define the combinedReducer object
export const combinedReducer = {
  firebase: LoginManager,
  SystemManager: systemSlice,
  header: headerSlice,
  rightDrawer: rightDrawerSlice,
  modal: modalSlice,
  news: newsSlice,
  tools: toolsSlice,
  user: userSlice,
  quiz: quizSlice,
  quizMarker: quizManagementSlice,
  org: orgManagementSlice,
  orgUsers: userManagementSlice,
  assistantChat: assistantChatSlice,
  ttsManager: ttsManager,
}

// Create the Redux store using configureStore from @reduxjs/toolkit
export const store = configureStore({
  reducer: combinedReducer,
})

const initialState = {}

// The rrfConfig object can be defined here if needed
const rrfConfig = {}

const rrfProps = {
  firebase,
  config: rrfConfig,
  dispatch: store.dispatch,
}

export function getReduxStore() {
  return store
}

export function getRrfProp() {
  return rrfProps
}
