import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { objectDateToString } from "../utils/utiltyHelper"

import {
  getOrgProfile,
  getOrgUsersByOrgID,
} from "../helperFunction/orgDBHelper"
import {
  defaultOrganization,
  exampleOrganization,
  Organization,
  OrganizationGroup,
} from "../type/Organization"
import { getAssistantChat } from "../helperFunction/chatroomDBHelper"
import { ChatMessage } from "../type/ChatMessage"

export interface asistantChatState {
  defaultMsg: string
  allMessages: ChatMessage[]
  isLoading: boolean
  isWaitingAssistant: boolean
}

export const getAssistantChatRecord = createAsyncThunk(
  "assistantChat/getProfile",
  async (chatroomID: string) => {
    return objectDateToString(await getAssistantChat(chatroomID))
  }
)

export const assistantChatSlice = createSlice({
  name: "assistantChat",
  initialState: {
    defaultMsg: "",
    allMessages: [],
    isLoading: true,
    isWaitingAssistant: false,
  } as asistantChatState,
  reducers: {
    addMessage: (state, action) => {
      state.allMessages = state.allMessages.concat(action.payload)
    },
    setMessages: (state, action) => {
      state.allMessages = action.payload
      state.isLoading = false
    },
    setIsWaitingAssistant: (state, action) => {
      state.isWaitingAssistant = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAssistantChatRecord.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getAssistantChatRecord.fulfilled, (state, action) => {
        if (action.payload.success) {
          state.allMessages = action.payload.data
        }
        state.isLoading = false
      })
      .addCase(getAssistantChatRecord.rejected, (state) => {
        state.isLoading = false
      })
  },
})

export const { setMessages, addMessage, setIsWaitingAssistant } =
  assistantChatSlice.actions

export default assistantChatSlice.reducer
