import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { ALERT_TYPE, BUTTON_TYPE } from "../enum/ALERT_SYSYEM"
import { Trans } from "react-i18next"
import { IconDefinition } from "@fortawesome/fontawesome-svg-core"
import classNames from "classnames"

export interface CustomAlertType {
  type: ALERT_TYPE.ERROR
  visible: boolean
  msg: string
}

export const defaultCustomAlertType: CustomAlertType = {
  type: ALERT_TYPE.ERROR,
  visible: false,
  msg: "",
}

interface PropsType {
  title: string
  alertType: ALERT_TYPE
  buttonType: {
    type: BUTTON_TYPE
    buttonText?: string
    buttonDisabledText?: string
    buttonIcon?: IconDefinition
  }
  description?: string
  buttonFn?: () => void
  onMouseEnter?: () => void
  onMouseLeave?: () => void
  icon?: IconDefinition
  isLoading?: boolean
  disabled?: boolean
  isHidden?: boolean
}

const CustomAlert: React.FC<PropsType> = ({
  title,
  alertType,
  buttonType,
  description,
  buttonFn,
  onMouseEnter,
  onMouseLeave,
  icon,
  isLoading,
  disabled,
  isHidden,
}) => {
  const alertTypeStyle = () => {
    switch (alertType) {
      case ALERT_TYPE.ERROR:
        return isAlertError
        break
      case ALERT_TYPE.INFO:
        return isAlertInfo
        break
      case ALERT_TYPE.WARNING:
        return isAlertWarning
        break
      case ALERT_TYPE.REQUEST:
        return isAlertRequest
        break
      case ALERT_TYPE.PAYMENT:
        return isAlertPayment
        break
      default:
        break
    }
  }
  const isButtonless =
    buttonType.type === BUTTON_TYPE.NONE ? "50px auto" : "50px auto 150px"
  const isAlertError = {
    borderColor: "#e7e9ec #e7e9ec #e7e9ec #b9231b",
    borderWidth: "1px 1px 1px 8px",
    gridTemplateColumns: isButtonless,
  }
  const isAlertInfo = {
    borderColor: "#e7e9ec #e7e9ec #e7e9ec #064e3b",
    borderWidth: "1px 1px 1px 8px",
    gridTemplateColumns: isButtonless,
  }
  const isAlertWarning = {
    borderColor: "#e7e9ec #e7e9ec #e7e9ec #a16207",
    borderWidth: "1px 1px 1px 8px",
    gridTemplateColumns: isButtonless,
  }
  const isAlertRequest = {
    borderColor: "#e7e9ec #e7e9ec #e7e9ec #a16207",
    borderWidth: "1px 1px 1px 8px",
    gridTemplateColumns: "50px auto 100px",
  }
  const isAlertPayment = {
    borderColor: "#e7e9ec #e7e9ec #e7e9ec #e0e7ff",
    borderWidth: "1px 1px 1px 8px",
    gridTemplateColumns: isButtonless,
  }
  const iconExisit = () => {
    if (icon) {
      return <FontAwesomeIcon icon={icon} />
    }
  }
  const btnIconExisit = () => {
    if (buttonType.buttonIcon) {
      return <FontAwesomeIcon icon={buttonType.buttonIcon} className="mr-2" />
    }
  }
  const helpIconExisit = () => {
    if (buttonType.buttonIcon) {
      return <FontAwesomeIcon icon={buttonType.buttonIcon} />
    }
  }
  return (
    <div
      className={classNames(
        " py-2 pl-4 md:pl-1 relative w-full items-center mb-5 font-medium py-1 px-2 bg-white hover:bg-gray-50 rounded-tl rounded-bl rounded-tr-xl rounded-br-xl shadow-md text-gray-700",
        "border-l-8 border-red-700",
        {
          hidden: isHidden,
          "lg:grid": alertType === ALERT_TYPE.REQUEST,
          "md:grid": alertType !== ALERT_TYPE.REQUEST,
        }
      )}
      style={alertTypeStyle()}>
      <div
        slot="avatar"
        className={classNames("hidden mx-5 ", {
          "lg:block": alertType === ALERT_TYPE.REQUEST,
          "md:block": alertType !== ALERT_TYPE.REQUEST,
        })}>
        {iconExisit()}
      </div>
      <div className="flex">
        <div
          slot="avatar"
          className={classNames("mx-5 self-center  block", {
            "lg:hidden": alertType === ALERT_TYPE.REQUEST,
            "md:hidden": alertType !== ALERT_TYPE.REQUEST,
          })}>
          {iconExisit()}
        </div>
        <div
          className={classNames(" font-normal max-w-full flex-initial", {
            "text-sm lg:text-base": alertType === ALERT_TYPE.REQUEST,
            "text-xl": alertType !== ALERT_TYPE.REQUEST,
          })}>
          <div className="py-2">
            <Trans>{title}</Trans>
            <div className="text-sm font-base">
              <Trans>{description}</Trans>
            </div>
          </div>
        </div>
      </div>
      <div>
        {buttonType.type === BUTTON_TYPE.TEXT_ONLY && (
          <div>
            <button
              className={classNames(
                "btn bg-gray-200 hover:bg-gray-300 text-neutral w-full border-inherit hover:border-inherit",
                {
                  "btn-sm": alertType === ALERT_TYPE.REQUEST,
                  loading: isLoading,
                }
              )}
              onClick={buttonFn}
              disabled={disabled}>
              {disabled && !isLoading ? (
                <Trans>{buttonType.buttonDisabledText}</Trans>
              ) : (
                <Trans>{buttonType.buttonText}</Trans>
              )}
            </button>
          </div>
        )}
        {buttonType.type === BUTTON_TYPE.WITH_ICON && (
          <div>
            <button
              className={classNames(
                "btn bg-gray-200 hover:bg-gray-300 text-neutral w-full border-inherit hover:border-inherit",
                {
                  loading: isLoading,
                }
              )}
              onClick={buttonFn}
              disabled={disabled}>
              {btnIconExisit()}
              {disabled && !isLoading ? (
                <Trans>{buttonType.buttonDisabledText}</Trans>
              ) : (
                <Trans>{buttonType.buttonText}</Trans>
              )}
            </button>
          </div>
        )}

        {buttonType.type === BUTTON_TYPE.HELP_BTN && (
          <div className="flex justify-end">
            <button
              className={classNames(
                "btn md:btn-circle btn-sm bg-gray-200 hover:bg-gray-300 text-neutral w-full md:w-8 border-inherit hover:border-inherit",
                {
                  loading: isLoading,
                }
              )}
              onClick={buttonFn}
              onMouseEnter={onMouseEnter}
              onMouseLeave={onMouseLeave}
              disabled={disabled}>
              {helpIconExisit()}
              {disabled && !isLoading ? (
                <Trans>{buttonType.buttonDisabledText}</Trans>
              ) : (
                <Trans>{buttonType.buttonText}</Trans>
              )}
            </button>
          </div>
        )}
      </div>
    </div>
  )
}
export default CustomAlert
