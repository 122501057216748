import {
  addDoc,
  collection,
  getDocs,
  getFirestore,
  limit,
  orderBy,
  query,
} from "firebase/firestore"
import firebaseApp from "../config/firebase"
import { ChatMessage } from "../type/ChatMessage"

const db = getFirestore(firebaseApp)
/**
 * send message from NewMessageContainer
 * @param {string} chatroomID - chatroomID
 * @param {ChatMessage} data - data of Message
 * @param {string} uid - the user uid who sent this message
 */

const sendChatroomMessage = (
  chatroomID: string,
  data: any,
  uid: string
): Promise<{
  success: boolean
}> => {
  return new Promise(async (resolve) => {
    await addDoc(collection(db, "ChatRoom", chatroomID, "Message"), {
      ...data,
      createUserID: uid,
      createDate: new Date(),
    })
      .then((res) => {
        console.log("sent message")
        return resolve({
          success: true,
        })
      })
      .catch((err) => console.log(err))
  })
}

/**
 * get assistant Chatroom by chatroom ID
 * @param {string} id - chatroom ID
 */

const getAssistantChat = async (
  chatroomID: string
): Promise<
  | {
      success: true
      data: ChatMessage[]
    }
  | {
      success: false
    }
> => {
  return new Promise(async (resolve) => {
    let dbRef = query(
      collection(db, "ChatRoom", chatroomID, "Message"),
      orderBy("createDate", "desc")
    )

    await getDocs(query(dbRef, limit(25)))
      .then((docs) => {
        if (docs && !docs.empty) {
          const resultMessage: ChatMessage[] = docs.docs.map(
            (doc) =>
              ({
                ...doc.data(),
                id: doc.id,
              } as ChatMessage)
          )
          let newAllMessage: ChatMessage[] = []

          resultMessage.forEach((msg) => {
            newAllMessage.push(msg)
          })

          newAllMessage = newAllMessage.slice().reverse()

          resolve({ success: true, data: [...newAllMessage] })
        } else {
          resolve({ success: false })
        }
      })
      .catch((e) => {
        console.log("error", e)
      })
  })
}

export { sendChatroomMessage, getAssistantChat }
