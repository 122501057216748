import React, { useImperativeHandle, useState } from "react"
import { MC, ShortQuestion } from "../../type/Question"

interface PropsType {
  data: ShortQuestion
}

const ShortQuestionCard: React.FC<PropsType> = ({ data }, ref: any) => {
  const [answer, setAnswer] = useState<string>("")

  useImperativeHandle(ref, () => ({
    returnData() {
      return answer
    },
  }))

  return (
    <div>
      <h2 className="card-title text-gray-600">
        <span className="badge badge-lg">Short Question</span>
      </h2>

      <div
        className="blog my-4"
        dangerouslySetInnerHTML={{
          __html: data.question as string,
        }}
      />

      <textarea
        placeholder="Your answer"
        className="textarea textarea-bordered textarea-md w-full"
        value={answer}
        onChange={(e) => setAnswer(e.target.value)}></textarea>
    </div>
  )
}
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export default React.forwardRef(ShortQuestionCard)
